import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Message, MessageService, ConfirmationService } from 'primeng/api';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { FormValidatorService } from '../../../shared/service/form-validator/form-validator.service';
import * as MOMENT from 'moment';
import { CompanyService } from '../../service/company.service';
import { CompanySettingServiceV2 } from '../../service/company-setting.v2.service';
import { IpAddressSettingServiceV2 } from '../../service/ip-address-setting.v2.service';
import { DocumentsService } from '../../../shared/service/documents-upload/documents.service';
import { Constants, WEEK_DAYS_FULL } from '../../../shared/model/constants';
import { IdleService } from '../../../idle/idle.service';
import { CompanySettingV2 } from '../../../company/model/v2/company-setting.model';
import { IpAddressSetting } from '../../../company/model/v2/ip-address.model';
import { AgencyService } from '../../../agency/service';
import * as _ from 'lodash';
import { debounceTime, map } from 'rxjs/operators';
import { PayrollSettingService } from '../../../payroll-setting/service/payroll-setting.service';
import { PayrollSetting } from '../../../payroll-setting/model/payroll-setting.model';
import { NGDropdownModel } from '../../../payroll-setting/utility/model.utility';
import { GetNotifyForModel } from '../../../agency/model/get-notify-for.model';
import { ModelUtility } from '../../../agency/utility/model.utility';
import { NotificationType, NotificationService } from '../../../crm/service/notification.service';
import { TimeUtility } from '../../../shared/utility/time.utility';
import { Observable, forkJoin, of, Subscription } from 'rxjs';
import { FileUpload } from 'primeng/fileupload';
import { DocumentsServicev2 } from 'app/shared/service/documents-upload/document.v2.service';
import { EmployeeService } from '../../../employee/service/employee.service';
import { OperatorService } from 'app/employee/service/v2/operator.v2.service';
import { DepartmentServicev2 } from '../../../department/service/v2/department.v2.service';
import { LangChangeEvent, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DATE_FORMATS } from 'app/shared/data/config-common';
import { Employee } from 'app/employee/model/employee.model';
import { SmsMonitoringService } from 'app/phonesystem/service/sms-monitoring.service';
import { Calendar } from 'primeng';
import { DatePipe } from '@angular/common';
import { DocumentData } from 'app/shared/model/document-data.model';
import { AngularFireDatabase } from '@angular/fire/database';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import axios from 'axios';
import { environment } from 'environments/environment';
import { MetadataModel } from 'app/reports/model/metadata.model';
import { ReportTemplateService } from 'app/reports/service/report-template.service';

declare function checkAvailablePrinters(success, error);

@Component({
    selector: 'app-company-setting-new',
    templateUrl: './company-setting-new.component.html',
    styleUrls: ['./company-setting-new.component.css'],
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
        AuthService, FormValidatorService, CompanyService, DocumentsService,
        CompanySettingServiceV2, IpAddressSettingServiceV2, OperatorService, SmsMonitoringService]
})
export class CompanySettingNewComponent implements OnInit, OnDestroy {
    private static readonly SEND_TIME_INTERVAL = 15; // minutes
    private static readonly HOURS_IN_DAY = 24;
    private static readonly MINUTES_IN_HOUR = 60;
    private readonly ONLY_ACTIVE_EMPLOYEE = 1;
    @Input()
    companyId: number;
    @Input()
    readOnly = false;
    @Input()
    formAction: string;

    loading = true;
    msgs: Message[];
    agencySettingForm: UntypedFormGroup;
    // agencySetting: AgencySetting = new AgencySetting;
    companySetting: CompanySettingV2 = new CompanySettingV2;
    enableAlertPunchInOut = false;
    enableProtestHour = false;
    reminderOptions: any[];
    otAlertOptions: any[];
    otAlertType: any[];
    reminderTimePeriodOptions: any[];
    reminderRepeatEveryOptions: any[];
    employeeMenuOptions: any[];
    listIpAddress: IpAddressSetting[];
    listIpAddressTemp: any;
    ipAddress: IpAddressSetting = new IpAddressSetting;
    length: any;
    employeeMenus: any[];
    crmMenus: any[];
    accountMenu: any[];
    bilingMenu: any[];
    employeeDepartments: any[] = [];
    crmDepartments: any[] = [];
    otherDepartments: any[] = [];
    otherMenus: any[];
    userNameOption: any[];
    is2ndVerificationEmployeeEnable: any;
    is2ndVerificationAdminEnable: any
    errorMessagePattern = {
        required: '{} is required'
    };
    locationIp: string;
    locationName: string;
    agencyId: number;
    formMode = Constants.FORM_MODE.CREATE;
    payrollSetting: PayrollSetting;
    localSongFile: FileUpload;
    localLogoFile: FileUpload;
    localbadgeFile: FileUpload;
    notificationMethods: any;
    calendarNotificationMethods: any;
    seniorPunchInOutNotificationToEmergencyContactMethods: any;
    otAlertSMSMethod: any;
    otAlertEmailMethod: any;
    otAlertTypeMethod: string;
    overtimeValidation: any;
    promptPasswordAt: number;
    overtimeAlert: any;
    smsNotification = '';
    isNValidEmail = false;
    isNValidSupportEmail = false;
    isEmptySupportEmail = false;
    isNValidFinanceEmail = false;
    notificationMethodEmail = '';
    dropDownPositions: NGDropdownModel[] = [];
    otAlertSMSOptions: NGDropdownModel[] = [];
    getNotifyFor: GetNotifyForModel[] = [];
    historyShow = false;
    operationDays: any[] = [];
    sendTos: NGDropdownModel[] = [];
    private timesOfDay = [];
    minDateValue = new Date();
    badgeFile: any = {};
    birthdaySongFile: any;
    daysOfWeek = [
        { label: WEEK_DAYS_FULL['0'], value: '0' },
        { label: WEEK_DAYS_FULL['1'], value: '1' },
        { label: WEEK_DAYS_FULL['2'], value: '2' },
        { label: WEEK_DAYS_FULL['3'], value: '3' },
        { label: WEEK_DAYS_FULL['4'], value: '4' },
        { label: WEEK_DAYS_FULL['5'], value: '5' },
        { label: WEEK_DAYS_FULL['6'], value: '6' },
    ];
    isEditAble: Boolean = false;
    showInfoDialog:Boolean = false;
    enablePlanAssignmentTime: Boolean = false;
    enableEmployeeEndTimeRemider: Boolean = false;
    displayRemainingDays: any;
    historyType: string;
    historyHeader: string;
    otHourFormat: string = '24';
    otHour: any;
    otHourStr: any;
    @ViewChild('calendar') datePicker : Calendar;
    isSaving = false;

    clockInOutConditionOptions = [
        { label: 'Allow all user to Clock', value: 'ALL_TO_CLOCK' },
        { label: 'Block user with missed clock', value: 'BLOCK_WITH_MISSED_CLOCK' }
    ];

    jobReportDayOptions = [
        { label: 'Monday', value: 'Monday' },
        { label: 'Tuesday', value: 'Tuesday' },
        { label: 'Wednesday', value: 'Wednesday' },
        { label: 'Thursday', value: 'Thursday' },
        { label: 'Friday', value: 'Friday' },
        { label: 'Saturday', value: 'Saturday' },
        { label: 'Sunday', value: 'Sunday' }
    ];
    jobReportEveryOptions = [
        { label: '1 hour', value: '1' },
        { label: '2 hour', value: '2' },
        { label: '3 hour', value: '3' },
        { label: '4 hour', value: '4' },
        { label: '6 hour', value: '6' },
        { label: '8 hour', value: '8' },
    ];
    perOptions = [
        { label: 'Weekly', value: 'Weekly' },
        { label: 'Monthly', value: 'Monthly' },
        { label: 'Yearly', value: 'Yearly' }
    ];
    
    quotaFrequencyOptions = [
        { label: 'One time', value: 'One time' },
        { label: 'Weekly', value: 'Weekly' },
        { label: 'Monthly', value: 'Monthly' },
        { label: 'Yearly', value: 'Yearly' }
    ];

    bulkMessageLimitOptions = [
        { label: '10', value: '10' },
        { label: '50', value: '50' },
        { label: '100', value: '100' },
        { label: '250', value: '250' },
        { label: '500', value: '500' },
        { label: 'Unlimited', value: 'Unlimited' }
    ];
    notificationOptions: any[] = [
        {label: 'Yes', value: true},
        {label: 'No', value: false}
    ];
    requiredHoursStatusOptions: any[] = [
        {label: 'On', value: true},
        {label: 'Off', value: false}
    ];
    hourFormats = [
        { label: '12 Hour', value: '12' },
        { label: '24 Hour', value: '24' }
      ];

    smsQuotaFrequencyMethod: string;
    mmsQuotaFrequencyMethod: string;

    isDisabledSMS_MMS: Boolean = false;

    audioFile: HTMLAudioElement;
    showVolume: Boolean = false;
    muteVolume: Boolean = false;
    loadingUploadLogofile: Boolean = false;
    loadingUploadbadgefile: Boolean = false;
    isPlatform: Boolean = false;
    fillAllEmployeeEmailLoading: boolean = false;
    employees: Employee[];
    suggestionsForBaseEmailFullList = <any>[];
    suggestionsForBaseEmail = <any>[];
    afterQuotaOptions = [
        { label: 'Restrict Service', value: 'TEMPORARILY_RESTRICTED' },
        { label: 'Add Amount', value: 'ADD_AMOUNT' },
        { label: 'Add Percent', value: 'ADD_PERCENT' }
    ];

    clientInvoiceNotificationOptions = [
        { label: 'Email Invoice & Auto-Pay', value: 'ENABLE_AUTO_PAY_AND_SEND_MAIL' },
        { label: 'Do Not Email Invoice & Auto-Pay', value: 'DISABLE_AUTO_PAY_AND_SEND_MAIL' }
    ];

    EmailToggle: Boolean = false;
    SMSToggle: Boolean = false;
    checkboxStates: { [id: string]: boolean } = {};
    @ViewChild('songfile') songfile: any;

    followMeList = <any>[];
    selectedItemCount: number = 0;
    selectedItemCountLabel: any;
    multiSelectLabel: string = 'Select Items';
    creditSettingDialog = false;
    creditDollarValue;
    creditValue;
    creditSmsValue;
    creditMmsValue;

    subscriptions: Subscription = new Subscription();

    alreadyPaidOptions = [
        { label: 'Active', value: 'Active' },
        { label: 'Inactive', value: 'Inactive' }
    ];

    disabledData = true;

    printerDevices = [];
    currentPrinterDevices = [];

    showViewResidentQrCodeDialog = false;
    residentQrCodeUrl = '';

    replySmsOptions = [
        { label: 'Free', value: 'free' },
        { label: 'Not Free', value: 'not_free' }
    ];

    financeEmailList: any[] = [];

    pinFacialRecognitionOptions = [
        { label: 'Automatically take Picture after using PIN', value: 'AUTO_TAKE_PICTURE' },
        { label: 'Allow user to take photo Manually after enter PIN', value: 'MANUAL_TAKE_PICTURE' },
        { label: 'Can use PIN or Facial Recognition', value: 'USE_PIN_OR_FACIAL_RECOGNITION' }
    ];

    punchValidationOptions = [
        { label: 'Must verify Punch in/out', value: 'MUST_VERIFY' },
        { label: 'After 24hrs automatically Validate Punch in/out', value: 'AFTER_24HRS' },
        { label: 'No Verification needed', value: 'NO_VERIFICATION' }
    ];
    sendToObjs = [];
    metadata: any = {
        objects: [],
        employees: [],
        admins: []
    };
    constructor(private formBuilder: UntypedFormBuilder,
        private authService: AuthService,
        private notificationService: NotificationService,
        private agencyService: AgencyService,
        private documentService: DocumentsService,
        private documentServicev2: DocumentsServicev2,
        private companySettingServiceV2: CompanySettingServiceV2,
        private ipAddressSettingServiceV2: IpAddressSettingServiceV2,
        private router: Router,
        // private ipAddressService: IpAddressService,
        private messageService: MessageService,
        private payrollSettingService: PayrollSettingService,
        private idleService: IdleService,
        private deparmentServicev2: DepartmentServicev2,
        private employeeService: EmployeeService,
        private operatorService: OperatorService,
        private formValidator: FormValidatorService,
        private translate: TranslateService,
        private translatePipe: TranslatePipe,
        private smsMonitoringService: SmsMonitoringService,
        private datePipe: DatePipe,
        private confirmationService: ConfirmationService,
        private db: AngularFireDatabase,
        private monitoringDetailsService: MonitoringDetailsService,
        private reportTemplateService: ReportTemplateService,
    ) {
        this.translate.use(this.translate.currentLang ? this.translate.currentLang : 'en');
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.translate.setDefaultLang(event.lang);
            this.translate.use(event.lang);
        });
        this.isEditAble = this.authService.isEmployeeRole() ? true :
          this.authService.getLoggedInUserEditList().includes('78');
        this.isPlatform = this.authService.isSuper() || this.authService.isSubSuper();
    }


    checkEmail() {
        this.EmailToggle = !this.EmailToggle;
        if (this.EmailToggle) {
            this.agencySettingForm.get('employeeMissingPunchNotificationEmail').enable();
        } else {
            this.agencySettingForm.get('employeeMissingPunchNotificationEmail').disable();
        }
    }
    checkSMS() {
        this.SMSToggle = !this.SMSToggle;
        if (this.SMSToggle) {
            this.agencySettingForm.get('smsNumber').enable();
        } else {
            this.agencySettingForm.get('smsNumber').disable();
        }
    }
    openSongFileSelector() {
        const fileInput = this.songfile.el.nativeElement.querySelector('input[type="file"]');
        if (fileInput) {
            fileInput.click();
        } else {
            console.error("Couldn't find the song file input");
        }
      }
    ngOnInit() {
        this.getPrinterDevice();
        this.initForm();
        const departmentOptions = <any>{};

        if (this.companyId !== 0) {
            departmentOptions.companyId = this.companyId;
        }
        this.deparmentServicev2.getDropdown(departmentOptions).subscribe(res => {
            const resObj: any = res;
            resObj.data.forEach(department => {
                const dep = { value: `${department.key}`, label: department.value };
                this.employeeDepartments.push(dep);
                this.crmDepartments.push(dep);
                this.otherDepartments.push(dep);
            });
        });

        this.operationDays = [
            { label: 'Monday', value: 'Mon' },
            { label: 'Tuesday', value: 'Tue' },
            { label: 'Wednesday', value: 'Wed' },
            { label: 'Thursday', value: 'Thu' },
            { label: 'Friday', value: 'Fri' },
            { label: 'Saturday', value: 'Sat' },
            { label: 'Sunday', value: 'Sun' }
        ];

        for (let h = 0; h < CompanySettingNewComponent.HOURS_IN_DAY; h++) {
            const hour = TimeUtility.checkTime(h);
            for (let m = 0; m < CompanySettingNewComponent.MINUTES_IN_HOUR; m += CompanySettingNewComponent.SEND_TIME_INTERVAL) {
                const min = TimeUtility.checkTime(m);
                const dateTime = new Date();
                dateTime.setHours(h, m, 0, 0);
                this.timesOfDay.push({ label: hour + ':' + min, value: dateTime.getTime() });
            }
        }

        this.companySetting.getNotifyFor = [];
        this.getNotifyFor = ModelUtility.getNotifyfor();
        // this.agencyId = this.companyId; //activatedRoute.snapshot.params['companyId'];
        this.loadDepartments();
        this.loading = true;
        this.employeeService.getEmployeeListByCompanyId(this.companyId, this.ONLY_ACTIVE_EMPLOYEE).subscribe((res: any) => {
            if (res.status === 'SUCCESS') {
                this.sendTos = res.data.map(employee => ({
                    label: employee.fullName,
                    value: employee.value
                }));
            }
        });
        this.sendToObjs = [];
        this.reportTemplateService.getMetadata().subscribe((data: MetadataModel) => {

            this.metadata = data;
            const objects: any[] = this.metadata.objects;
            console.log(this.metadata);
            objects.forEach(object => {
                const filters = _.sortBy(object.filters, 'label');
                const columns = _.sortBy(object.columns, 'label');
                object.filters = filters;
                object.columns = columns;
            });
            this.metadata.objects = _.sortBy(objects, 'label');
            this.sendToObjs.push(...this.metadata.employees);
            // this.sendTos.push(...this.metadata.admins);
            console.log('getMetadata sendToObjs: ', this.sendToObjs);
            console.log('getMetadata sendTos: ', this.sendTos);
        });
        this.fillAllEmployeeEmail();
        this.companySettingServiceV2.getCompanySettingByCompanyId(this.companyId)
            .subscribe((response: any) => {
                if (response.status === 'SUCCESS') {
                    // if (response.data) {
                    // this.agencyId = response.data[0].id;
                    // this.agencyService.getAgencySetting(this.agencyId)
                    //     .subscribe((response: any) => {
                    if (response.data) {
                        console.log('response.data', response.data);
                        this.companySetting = response.data;
                        this.getLogoImg(this.companySetting.logoId);
                        this.getBadge(this.companySetting.badge);
                        this.getBirthdaySong(this.companySetting.birthdaySong);
                        this.isDisabled(this.companySetting.mobileVisitVerification);
                        this.companySetting.getNotifyFor = response.data.getNotifyFor ? response.data.getNotifyFor : [];
                        this.companySetting.credits = this.companySetting.credits ? this.companySetting.credits : '0';
                        this.companySetting.assistanceHours = this.companySetting.assistanceHours ? this.companySetting.assistanceHours : '0';
                        this.selectGetNotiFor();
                        this.enableAlertPunchInOut = _.includes(this.companySetting.getNotifyFor, 'editPunchInOut');
                        this.enablePlanAssignmentTime = _.includes(this.companySetting.getNotifyFor, 'Plan/Assignment');
                        this.enableProtestHour = _.includes(this.companySetting.getNotifyFor, 'protestHour');
                        if (response.data.sendAt) {
                            this.companySetting.sendAt = MOMENT.utc(response.data.sendAt).toDate();
                            this.companySetting.sendAtStr = MOMENT.utc(response.data.sendAt).format('HH:mm');
                        }

                        if (response.data.planAssignmentAlertTime) {
                            this.companySetting.planAssignmentAlertTime = MOMENT.utc(response.data.planAssignmentAlertTime).toDate();
                            this.companySetting.planAssignmentAlertTimeStr = MOMENT.utc(response.data.planAssignmentAlertTime).format('HH:mm');
                        }

                        if (response.data.employeeEndTimeRemider) {
                            this.companySetting.employeeEndTimeRemider = MOMENT.utc(response.data.employeeEndTimeRemider).toDate();
                            this.companySetting.employeeEndTimeRemiderStr = MOMENT.utc(response.data.employeeEndTimeRemider).format('HH:mm');
                        }

                        if (response.data.atHours) {
                            this.companySetting.atHours = MOMENT.utc(response.data.atHours).toDate();
                            this.companySetting.atHoursStr = MOMENT.utc(response.data.atHours).format('hh:mm A');
                        }

                        if (response.data.requiredHours) {
                            this.companySetting.requiredHours = new Date(response.data.requiredHours);
                        }

                        if (response.data.supportResponseTime) {
                            this.companySetting.supportResponseTime = MOMENT.utc(response.data.supportResponseTime).toDate();
                            this.companySetting.supportResponseTimeStr = MOMENT.utc(response.data.supportResponseTime).format('HH:mm');
                        }
                        if (response.data.accountDepartmentList) {
                            this.companySetting.accountDepartmentList = response.data.accountDepartmentList.filter(item => item != "");
                        }
                        if (response.data.accountMenuList) {
                            this.companySetting.accountMenuList = response.data.accountMenuList.filter(item => item != "");
                        }
                        if (response.data.billingDepartmentList) {
                            this.companySetting.billingDepartmentList = response.data.billingDepartmentList.filter(item => item != "");
                        }
                        if (response.data.billingMenuList) {
                            this.companySetting.billingMenuList = response.data.billingMenuList.filter(item => item != "");
                        }

                        if (response.data.crmDepartmentList) {
                            this.companySetting.crmDepartmentList = response.data.crmDepartmentList.filter(item => item != "");
                        }
                        if (response.data.crmMenuList) {
                            this.companySetting.crmMenuList = response.data.crmMenuList.filter(item => item != "");
                        }
                        if (response.data.employeeDepartmentList) {
                            this.companySetting.employeeDepartmentList = response.data.employeeDepartmentList.filter(item => item != "");
                        }
                        if (response.data.employeeMenuList) {
                            this.companySetting.employeeMenuList = response.data.employeeMenuList.filter(item => item != "");
                        }

                        if (response.data.otherDepartmentList) {
                            this.companySetting.otherDepartmentList = response.data.otherDepartmentList.filter(item => item != "");
                        }
                        if (response.data.otherMenuList) {
                            this.companySetting.otherMenuList = response.data.otherMenuList.filter(item => item != "");
                        }

                        if (response.data.sendAlert) {
                            this.companySetting.sendAlert = MOMENT.utc(response.data.sendAlert).toDate();
                            this.companySetting.sendAlertStr = MOMENT.utc(response.data.sendAlert).format('HH:mm');
                        }
                        if (response.data.updateTrackingEvery) {
                            this.companySetting.updateTrackingEvery = MOMENT.utc(response.data.updateTrackingEvery).toDate();
                            this.companySetting.updateTrackingEveryStr = MOMENT.utc(response.data.updateTrackingEvery).format('HH:mm');
                        }

                        this.companySetting.maxLoginAttemptCountAdmin = response.data.maxLoginAttemptCountAdmin;
                        this.companySetting.maxLoginAttemptCountEmployee = response.data.maxLoginAttemptCountEmployee;
                        this.is2ndVerificationEmployeeEnable = response.data.is2ndVerificationEmployeeEnable? 1 : 0;
                        this.is2ndVerificationAdminEnable = response.data.is2ndVerificationAdminEnable? 1: 0;
                        if (response.data.reminderRepeatEndOn) {
                            this.companySetting.reminderRepeatEndOn = new Date(response.data.reminderRepeatEndOn);
                        }
                        if(!this.companySetting.pinVerificationLimit) {
                            this.companySetting.pinVerificationLimit = 1
                        }

                        if (this.companySetting.smsPrice) {
                            this.smsPrice = '$' + this.companySetting.smsPrice.toFixed(2);
                        }
                        if (this.companySetting.smsUsageCost) {
                            this.smsUsageCost = '$' + this.companySetting.smsUsageCost.toFixed(2);
                        }
                        if (this.companySetting.mmsPrice) {
                            this.mmsPrice = '$' + this.companySetting.mmsPrice.toFixed(2);
                        }
                        if (this.companySetting.mmsUsageCost) {
                            this.mmsUsageCost = '$' + this.companySetting.mmsUsageCost.toFixed(2);
                        }
                        if (!this.companySetting.clientInvoiceNotification) {
                            this.companySetting.clientInvoiceNotification = 'ENABLE_AUTO_PAY_AND_SEND_MAIL';
                        }
                        if (!this.companySetting.alreadyPaid) {
                            this.companySetting.alreadyPaid = 'Active';
                        }
                        if (this.companySetting.unitPrice) {
                            this.unitPrice = '$' + this.companySetting.unitPrice.toFixed(2);
                        }
                        
                        setTimeout(() => 
                            {
                                if (this.companySetting.jrNeedToNotifyOtherPeople && this.companySetting.jrNeedToNotifyOtherPeople.length > 0) {
                                    const jrNeedToNotifyOtherPeople = this.companySetting.jrNeedToNotifyOtherPeople;
                                    this.companySetting.jrNeedToNotifyOtherPeopleArr = [];
                                    jrNeedToNotifyOtherPeople.forEach((email: any) => {
                                        const obj = this.suggestionsForBaseEmailFullList.find(item => item.email === email);
                                        if (obj) {
                                            this.suggestionsForBaseEmail.push(obj);
                                            this.companySetting.jrNeedToNotifyOtherPeopleArr.push(obj);
                                        }
                                    });
                                }
                            }
                        , 2000);
                        
                        this.followMeList = this.companySetting.followMeList;
                        if (this.followMeList && this.followMeList.length > 0) {
                            for (let i = 0; i < this.followMeList.length; i++) {
                                const index = i + 1;
                                this.agencySettingForm.addControl('followMe'+ index, new UntypedFormControl({ value: this.followMeList[i]}));
                            }
                        } else {
                            this.followMeList = [];
                        }

                        this.financeEmailList = [];
                        if (this.companySetting.financeEmail) {
                            let financeEmailArr = this.companySetting.financeEmail.split(',');
                            for (let i = 0; i < financeEmailArr.length; i++) {
                                if (this.formValidator.checkValidEmails(financeEmailArr[i])) {
                                    this.financeEmailList.push(financeEmailArr[i])
                                }
                            }
                        }

                        this.formMode = Constants.FORM_MODE.UPDATE;
                    } else {
                        this.formMode = Constants.FORM_MODE.CREATE;
                        this.companySetting = new CompanySettingV2();
                    }

                    this.companySetting.smsQuota = this.companySetting.smsQuota ? this.companySetting.smsQuota : 20;

                    this.checkedActiveEmail();
                    // Init IP address list
                    this.ipAddressSettingServiceV2.findAllByCompanyId(this.companyId).subscribe(res => {
                        const resObj: any = res;
                        this.listIpAddress = resObj.data;
                        this.listIpAddressTemp = JSON.stringify(this.listIpAddress);
                        this.length = this.listIpAddress.length;
                    });

                    // console.log('------agency settings ', this.companySetting);
                    this.payrollSettingService.getPayrollSetting(this.agencyId).subscribe(res => {
                        const resObj: any = res;
                        this.payrollSetting = resObj.data;
                        // this.getOverrtime(rowData);
                    });
                    this.calendarNotificationMethods = this.companySetting.calendarNotificationMethod;
                    this.seniorPunchInOutNotificationToEmergencyContactMethods = this.companySetting.seniorPunchInOutNotificationToEmergencyContactMethod;
                    this.overtimeValidation = this.companySetting.overtimeValidation;
                    this.promptPasswordAt = this.companySetting.promptPasswordAt;
                    this.overtimeAlert = this.companySetting.overtimeAlert;
                    this.otAlertTypeMethod = this.companySetting.otAlertType;
                    console.log('test date',this.datePipe.transform(new Date(), 'yyyy-MM-ddThh:mm:ss.SSSZ'))
                    if (this.companySetting.otAlertType === 'after_ot') {
                        if (this.companySetting.otAlertFormatHour) {
                            this.otHourFormat = this.companySetting.otAlertFormatHour;
                            this.otHourStr = this.companySetting.otAlertHour + ":" + this.companySetting.otAlertMinute;
                            const timeString = this.convertTimeStr();
                            this.otHour = new Date('1970-01-01T' + timeString );
                        }
                    }
                    this.otAlertEmailMethod = this.companySetting.otAlertEmail;
                    this.otAlertSMSMethod = this.companySetting.otAlertSMS;
                    this.displayRemainingDays = this.companySetting.displayRemainingDays;
                    if (this.companySetting.employeeMissingPunchNotificationMethod) {
                        this.notificationMethods = this.companySetting.employeeMissingPunchNotificationMethod;
                        this.notificationMethodEmail = this.companySetting.employeeMissingPunchNotificationEmail;
                        this.smsNotification = this.companySetting.smsNumber;

                        this.EmailToggle = this.companySetting.employeeMissingPunchNotificationMethod.includes('Email');

                        if (this.EmailToggle) {
                            this.agencySettingForm.get('employeeMissingPunchNotificationEmail').enable();
                        } else {
                            this.agencySettingForm.get('employeeMissingPunchNotificationEmail').disable();
                        }

                        this.SMSToggle = this.companySetting.employeeMissingPunchNotificationMethod.includes('SMS');
                        if (this.SMSToggle) {
                            this.agencySettingForm.get('smsNumber').enable();
                        } else {
                            this.agencySettingForm.get('smsNumber').disable();
                        }
                    }
                    if (this.companySetting.overtimeAlert) {
                        this.agencySettingForm.get('promptPasswordAt').enable();
                    } else {
                        this.agencySettingForm.get('promptPasswordAt').disable();
                        this.agencySettingForm.get('promptPasswordAt').setValue(null);
                    }

                    if (this.companySetting.overtimeValidation) {
                        this.agencySettingForm.get('otAlertHour').enable();
                        this.agencySettingForm.get('otAlertMinute').enable();
                    } else {
                        this.agencySettingForm.get('otAlertHour').disable();
                        this.agencySettingForm.get('otAlertMinute').disable();
                        this.agencySettingForm.get('otAlertHour').setValue(null);
                        this.agencySettingForm.get('otAlertMinute').setValue(null);
                    }


                    this.smsQuotaFrequencyMethod = this.companySetting.smsQuotaFrequency;
                    this.mmsQuotaFrequencyMethod = this.companySetting.mmsQuotaFrequency;

                    const condition: any = {
                        companyId: this.companyId
                    };
                    this.smsMonitoringService.countDataByCondition(condition).subscribe(res => {
                        const resObj: any = res;
                        if (resObj.status === 'SUCCESS') {
                            console.log('resObj', resObj);
                            if (resObj.data) {
                                this.companySetting.mmsCount = resObj.data.mmsCount;
                                this.companySetting.smsCount = resObj.data.smsCount;
                            }
                        }
                    });
                    if (this.companySetting.creditValue && this.companySetting.creditSmsValue && this.companySetting.creditMmsValue) {
                        this.smsMonitoringService.countDataByFrequency({
                            companyId: this.companyId,
                            frequency:  this.companySetting.smsQuotaFrequency
                        }).subscribe(res => {
                            const resObj: any = res;
                            if (resObj.status === 'SUCCESS') {
                                console.log('resObj', resObj);
                                if (resObj.data) {
                                    this.companySetting.smsCreditsUsed = resObj.data.smsCount * this.companySetting.creditSmsValue;
                                    this.companySetting.mmsCreditsUsed = resObj.data.mmsCount * this.companySetting.creditMmsValue;
                                }
                            }
                        });
                    }

                    this.loading = false;
                    this.msgs = [];
                }

            });
        
        this.subscriptions.add(this.db.object(`/company-setting/credit-setting-all`).valueChanges().pipe(debounceTime(200)).subscribe((event: any) => {
            if (event) {
                const currentTimestamp = new Date().getTime();
                if (event.updateTime > currentTimestamp - 15000) {
                    this.companySetting.creditDollarValue = event.creditDollarValue;
                    this.companySetting.creditValue = event.creditValue;
                    this.companySetting.creditSmsValue = event.creditSmsValue;
                    this.companySetting.creditMmsValue = event.creditMmsValue;
                    this.creditDollarValue = event.creditDollarValue;
                    this.creditValue = event.creditValue;
                    this.creditSmsValue = event.creditSmsValue;
                    this.creditMmsValue = event.creditMmsValue;
                }
            }
            }));
    }

    onChangeUpdateTrackEvery() {
        this.companySetting.updateTrackingEvery = MOMENT.utc(this.companySetting.updateTrackingEveryStr, 'HH:mm').toDate();
    }

    onChangeSupportResponseTime() {
        this.companySetting.supportResponseTime = MOMENT.utc(this.companySetting.supportResponseTime, 'HH:mm').toDate();
    }

    onChangeSendAt() {
        this.companySetting.sendAt = MOMENT.utc(this.companySetting.sendAtStr, 'HH:mm').toDate();
    }

    onChangeAtHour() {
        this.companySetting.atHours = MOMENT.utc(this.companySetting.atHoursStr, 'hh:mm A').toDate();
    }

    onChangePlanAssignmentAlertTime() {
        this.companySetting.planAssignmentAlertTime = MOMENT.utc(this.companySetting.planAssignmentAlertTimeStr, 'HH:mm').toDate();
    }
    
    onChangeEmployeeEndTimeRemider() {
        this.companySetting.employeeEndTimeRemider = MOMENT.utc(this.companySetting.employeeEndTimeRemiderStr, 'HH:mm').toDate();
    }

    checkedActiveEmail() {
        if (this.agencySettingForm.controls['sendActive'].value === true) {
            this.agencySettingForm.controls['emailSendTo'].setValidators([Validators.required]);
            this.agencySettingForm.controls['emailSendTo'].updateValueAndValidity();

            this.agencySettingForm.controls['financeEmail'].setValidators([Validators.required]);
            this.agencySettingForm.controls['financeEmail'].updateValueAndValidity();
            this.agencySettingForm.controls['dayOfWeek'].setValidators([Validators.required]);
            this.agencySettingForm.controls['dayOfWeek'].updateValueAndValidity();
            this.agencySettingForm.controls['atHours'].setValidators([Validators.required]);
            this.agencySettingForm.controls['atHours'].updateValueAndValidity();

        } else {
            this.agencySettingForm.controls['emailSendTo'].setValidators([]);
            this.agencySettingForm.controls['emailSendTo'].updateValueAndValidity();
            this.agencySettingForm.controls['financeEmail'].setValidators([]);
            this.agencySettingForm.controls['financeEmail'].updateValueAndValidity();
            this.agencySettingForm.controls['dayOfWeek'].setValidators([]);
            this.agencySettingForm.controls['dayOfWeek'].updateValueAndValidity();
            this.agencySettingForm.controls['atHours'].setValidators([]);
            this.agencySettingForm.controls['atHours'].updateValueAndValidity();
        }
        this.agencySettingForm.updateValueAndValidity();
    }

    getLogoImg(id) {
        this.documentService.getUploadedFile(id).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.companySetting.logo = resObj.data;
            }
        }, error1 => {
            // error
        });
    }

    getBadge(id) {
        this.documentService.getUploadedFile(id).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.badgeFile = resObj.data;
            }
        }, error1 => {
            // error
        });
    }

    getBirthdaySong(id) {
        this.documentService.getUploadedFile(id).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.birthdaySongFile = resObj.data;
                if (this.birthdaySongFile && this.birthdaySongFile.fileUrl) {
                    this.showVolume = true;
                }
            }
        }, error1 => {
            // error
        });
    }

    onKeyUp(event) {
        if (event.code == 'Minus') {
            event.preventDefault();
        }
    }
    changeHistoryHandler(payrollSettingId, mergedObject) {
        const admin = this.authService.getUserInfo();
        // console.log(mergedObject);

        const historyData = [];
        const fieldsName = ['employeeMissingPunchNotificationEmail', 'smsNumber', 'sendAt',
            'supportEmail', 'sendActive','emailSendTo','financeEmail', 'dayOfWeek','atHoursStr','logoId','badge'];
        fieldsName.forEach(element => {
            if (mergedObject[element] !== null && mergedObject[element] !== '') {
                const dataObj: any = {};
                let newValue = mergedObject[element];
                if (element === 'sendActive') {
                    newValue = mergedObject[element] === true ? 'Yes':'No';
                }
                if (element === 'dayOfWeek') {
                    newValue = WEEK_DAYS_FULL[mergedObject[element]];
                }
                if (element === 'emailSendTo') {
                    newValue = mergedObject[element] ? mergedObject[element].join(',') : '';
                }
                dataObj.fieldName = element;
                dataObj.oldValue = '';
                dataObj.newValue = newValue;
                dataObj.action = 'Created';
                dataObj.userId = payrollSettingId;
                dataObj.companySettingId = payrollSettingId;
                dataObj.userName = admin.username;
                historyData.push(dataObj);
            }
        });
        // console.log(historyData);
        this.companySettingServiceV2.saveHistoryData(historyData).subscribe((item: any) => {
            // console.log(item);
        })
    }
    getHistory() {

        this.historyShow = true;
    }
    async changeHistoryHandlerEdit(newClientData) {
        const admin = this.authService.getUserInfo();
        // console.log(newClientData);
        await this.companySettingServiceV2.getCompanySettingByCompanyId(newClientData.companyId).subscribe((item: any) => {
            const oldData = item.data;
            const historyData = [];
            const fieldsName = ['employeeMissingPunchNotificationEmail', 'smsNumber', 'sendAt',
                'supportEmail', 'sendActive','emailSendTo','financeEmail', 'dayOfWeek','atHours','logoId','badge'];
            fieldsName.forEach(element => {
                let newValue = newClientData[element];
                let oldValue = oldData[element];
                if (element === 'sendAt') {
                    oldValue = MOMENT.utc(oldData[element]).format('HH:mm');
                    newValue = MOMENT.utc(newClientData[element]).format('HH:mm');
                }
                if (element === 'atHours') {
                    oldValue = MOMENT.utc(oldData[element]).format('HH:mm');
                    newValue = MOMENT.utc(newClientData[element]).format('HH:mm');
                }
                if (element == 'sendActive') {
                    newValue = newClientData[element] === true ? 'Yes':'No';
                    oldValue = oldData[element] === true ? 'Yes':'No';
                }
                if (element === 'dayOfWeek') {
                    newValue = WEEK_DAYS_FULL[newClientData[element]];
                    oldValue = WEEK_DAYS_FULL[oldData[element]];
                }
                if (element == 'emailSendTo') {
                    newValue = newClientData[element] ? newClientData[element].join() : '';
                    oldValue = oldData[element] ?  oldData[element].join() : '';
                }
                if (newValue !== oldValue) {
                    const dataObj: any = {};
                    dataObj.fieldName = element;
                    dataObj.oldValue = oldValue;
                    dataObj.newValue = newValue;


                    if (dataObj.newValue.length === 0) {
                        dataObj.action = 'Deleted';
                    } else {
                        dataObj.action = 'Updated';
                    }

                    dataObj.companySettingId = newClientData.id;
                    dataObj.userName = admin.username;
                    historyData.push(dataObj);
                }
            });
            // console.log(historyData);
            this.companySettingServiceV2.saveHistoryData(historyData).subscribe((item: any) => {
                // console.log(item);
            });
        });
    }
    private selectGetNotiFor() {
        if (this.companySetting.getNotifyFor) {
            this.getNotifyFor = this.getNotifyFor.map((ministry) => {
                ministry.selected = (this.companySetting.getNotifyFor.indexOf(ministry.id) > -1);
                return ministry;
            });
            this.enableAlertPunchInOut = _.includes(this.companySetting.getNotifyFor, 'editPunchInOut');
            if (this.enableAlertPunchInOut) {
                this.checkboxStates['editPunchInOut'] = true;
                this.agencySettingForm.controls['sendAlertWhenPunchInOut'].enable();
                this.agencySettingForm.controls['sendAlertWhenPunchInOut'].setValidators([Validators.required]);
            } else {
                this.agencySettingForm.controls['sendAlertWhenPunchInOut'].disable();
                this.agencySettingForm.controls['sendAlertWhenPunchInOut'].clearValidators();
            }
            this.agencySettingForm.controls['sendAlertWhenPunchInOut'].updateValueAndValidity();

            this.enablePlanAssignmentTime = _.includes(this.companySetting.getNotifyFor, 'Plan/Assignment');
            if (this.enablePlanAssignmentTime) {
                this.checkboxStates['Plan/Assignment'] = true;
                this.agencySettingForm.controls['planAssignmentAlertTime'].enable();
                this.agencySettingForm.controls['planAssignmentAlertTime'].setValidators([Validators.required]);
            } else {
                this.agencySettingForm.controls['planAssignmentAlertTime'].disable();
                this.agencySettingForm.controls['planAssignmentAlertTime'].clearValidators();
            }
            this.agencySettingForm.controls['planAssignmentAlertTime'].updateValueAndValidity();

            this.enableEmployeeEndTimeRemider = _.includes(this.companySetting.getNotifyFor, 'employeeEndTime');
            if (this.enableEmployeeEndTimeRemider) {
                this.checkboxStates['employeeEndTime'] = true;
                this.agencySettingForm.controls['employeeEndTimeRemider'].enable();
                this.agencySettingForm.controls['employeeEndTimeRemider'].setValidators([Validators.required]);
            } else {
                this.agencySettingForm.controls['employeeEndTimeRemider'].disable();
                this.agencySettingForm.controls['employeeEndTimeRemider'].clearValidators();
            }
            this.agencySettingForm.controls['employeeEndTimeRemider'].updateValueAndValidity();

            this.enableProtestHour = _.includes(this.companySetting.getNotifyFor, 'protestHour');
            if (this.enableProtestHour) {
                this.checkboxStates['protestHour'] = true;
                this.agencySettingForm.controls['timeCorrectionQuota'].enable();
                this.agencySettingForm.controls['timeCorrectionQuota'].setValidators([Validators.required]);
                this.agencySettingForm.controls['timeCorrectionPer'].enable();
                this.agencySettingForm.controls['timeCorrectionPer'].setValidators([Validators.required]);
            } else {
                this.agencySettingForm.controls['timeCorrectionQuota'].disable();
                this.agencySettingForm.controls['timeCorrectionQuota'].clearValidators();
                this.agencySettingForm.controls['timeCorrectionPer'].disable();
                this.agencySettingForm.controls['timeCorrectionPer'].clearValidators();
            }
            this.agencySettingForm.controls['timeCorrectionQuota'].updateValueAndValidity();
            this.agencySettingForm.controls['timeCorrectionPer'].updateValueAndValidity();
        }
    }

    // ministry data checkbox
    getNotifyForCheck(event, value) {


        if (event.checked) {
            this.companySetting.getNotifyFor.push(value);
            this.checkboxStates[value] = event.checked;
          } else {
            const index = this.companySetting.getNotifyFor.indexOf(value);
            if (index > -1) {
              this.companySetting.getNotifyFor.splice(index, 1);
            }
            this.checkboxStates[value] = event.checked;
          }
        // console.log(this.companySetting.getNotifyFor);
        this.enableAlertPunchInOut = _.includes(this.companySetting.getNotifyFor, 'editPunchInOut');
        if (this.enableAlertPunchInOut) {
            this.agencySettingForm.controls['sendAlertWhenPunchInOut'].enable();
            this.agencySettingForm.controls['sendAlertWhenPunchInOut'].setValidators([Validators.required]);
        } else {
            this.agencySettingForm.controls['sendAlertWhenPunchInOut'].disable();
            this.agencySettingForm.controls['sendAlertWhenPunchInOut'].clearValidators();
        }
        this.agencySettingForm.controls['sendAlertWhenPunchInOut'].updateValueAndValidity();

        this.enablePlanAssignmentTime = _.includes(this.companySetting.getNotifyFor, 'Plan/Assignment');
        if (this.enablePlanAssignmentTime) {
            this.agencySettingForm.controls['planAssignmentAlertTime'].enable();
            this.agencySettingForm.controls['planAssignmentAlertTime'].setValidators([Validators.required]);
        } else {
            this.agencySettingForm.controls['planAssignmentAlertTime'].disable();
            this.agencySettingForm.controls['planAssignmentAlertTime'].clearValidators();
            this.agencySettingForm.patchValue({planAssignmentAlertTime:null})
        }
        this.agencySettingForm.controls['planAssignmentAlertTime'].updateValueAndValidity();

        this.enableEmployeeEndTimeRemider = _.includes(this.companySetting.getNotifyFor, 'employeeEndTime');
        if (this.enableEmployeeEndTimeRemider) {
            this.agencySettingForm.controls['employeeEndTimeRemider'].enable();
            this.agencySettingForm.controls['employeeEndTimeRemider'].setValidators([Validators.required]);
        } else {
            this.agencySettingForm.controls['employeeEndTimeRemider'].disable();
            this.agencySettingForm.controls['employeeEndTimeRemider'].clearValidators();
            this.agencySettingForm.patchValue({employeeEndTimeRemider:null})
        }
        this.agencySettingForm.controls['employeeEndTimeRemider'].updateValueAndValidity();

        this.enableProtestHour = _.includes(this.companySetting.getNotifyFor, 'protestHour');
        if (this.enableProtestHour) {
            this.agencySettingForm.controls['timeCorrectionQuota'].enable();
            this.agencySettingForm.controls['timeCorrectionQuota'].setValidators([Validators.required]);
            this.agencySettingForm.controls['timeCorrectionPer'].enable();
            this.agencySettingForm.controls['timeCorrectionPer'].setValidators([Validators.required]);
        } else {
            this.agencySettingForm.controls['timeCorrectionQuota'].disable();
            this.agencySettingForm.controls['timeCorrectionQuota'].clearValidators();
            this.agencySettingForm.controls['timeCorrectionPer'].disable();
            this.agencySettingForm.controls['timeCorrectionPer'].clearValidators();
        }
        this.agencySettingForm.controls['timeCorrectionQuota'].updateValueAndValidity();
        this.agencySettingForm.controls['timeCorrectionPer'].updateValueAndValidity();
    }

    hasError(fieldName: string, errorCode: string): boolean {
        return this.agencySettingForm.controls[fieldName] &&
            (this.agencySettingForm.controls[fieldName].dirty || this.agencySettingForm.controls[fieldName].touched) &&
            this.agencySettingForm.controls[fieldName].hasError(errorCode);
    }

    errorMessage(fieldName: string, errorCode: string) {
        return this.errorMessagePattern[errorCode].replace('{}', fieldName);
    }

    checkDuplicateIp(ipRecord, id?): boolean {
        let listIpAddress;
        if (id) {
            listIpAddress = this.listIpAddress.filter(ip => ip.id != id);
        } else {
            listIpAddress = this.listIpAddress.slice();
        }

        if (listIpAddress && listIpAddress.length > 0) {
            const current = ipRecord;
            for (let i = 0; i < listIpAddress.length; i++) {
                if ((current.locationIp === listIpAddress[i].locationIp)
                || (current.locationName && current.locationName.toLowerCase() == listIpAddress[i].locationName.toLowerCase())) {
                    return true;
                }
            }
        }

        return false;
    }

    onSaveNew(dataForm) {
        if (!this.isEditAble) {
            this.messageService.add({ severity: 'error',
                summary: this.translatePipe.transform('error'),
                detail: this.translatePipe.transform('message.permission') });
            return;
        }

        let checkChangeIpAdreess = false;
        if (this.locationName || this.locationIp) {
            if (!this.locationName) {
                this.messageService.add({ severity: 'error',
                    summary: this.translatePipe.transform('error'),
                    detail: 'Please enter location name' });
                return;
            }
            if (!this.locationIp) {
                this.messageService.add({ severity: 'error',
                    summary: this.translatePipe.transform('error'),
                    detail: 'Please enter location ip' });
                return;
            }
            checkChangeIpAdreess = true;
        } else {

            let listIpAddressTempJSON = this.listIpAddressTemp;
            let listIpAddressJSON = JSON.stringify(this.listIpAddress);
            if ((listIpAddressTempJSON || '').localeCompare(listIpAddressJSON) === 0) {
                checkChangeIpAdreess = false;
            } else {
                checkChangeIpAdreess = true;
            }
        }
        console.log('onSave listIpAddressTemp: ', this.listIpAddressTemp);
        console.log('onSave listIpAddress: ', this.listIpAddress);
        console.log('onSave listIpAddress JSON: ', JSON.stringify(this.listIpAddress));
        console.log('onSave checkChangeIpAdreess: ', checkChangeIpAdreess);

        if (checkChangeIpAdreess) {
            this.confirmationService.confirm({
                header: 'Confirmation',
                message: 'Would you like to Save Network Settings update?',
                accept: () => {
                    if (this.locationName && this.locationIp) {
                        this.createIpAddress();
                    }
                    if (this.listIpAddress && this.listIpAddress.length > 0) {
                        this.listIpAddress.forEach((item: any) => {
                            this.updateIpAddress(item.companyId, item.id, item);
                        });
                    }
                    this.onSave(dataForm);
                },
                reject: () => {
                    this.onSave(dataForm);
                }
            });
        } else {
            this.onSave(dataForm);
        }
    }

    onEmailChange(dataForm) {
        this.agencySettingForm.markAllAsTouched();
        if (this.formValidator.validateForm(this.agencySettingForm, dataForm)) {
            const emailCheckbox = this.notificationMethods === 'Email' || (_.indexOf(this.notificationMethods, 'Email') >= 0);
            this.isNValidEmail = emailCheckbox && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.notificationMethodEmail));

            if (this.isNValidEmail) {
                return;
            }
        }
    }

    onSave(dataForm) {
        this.agencySettingForm.markAllAsTouched();
        if (this.formValidator.validateForm(this.agencySettingForm, dataForm)) {
            const emailCheckbox = this.notificationMethods === 'Email' || (_.indexOf(this.notificationMethods, 'Email') >= 0);
            this.isNValidEmail = emailCheckbox && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.notificationMethodEmail));

            if (this.isNValidEmail) {
                this.messageService.add({ severity: 'error', summary: 'Invalid data', detail: 'Please fill out the required fields' });
                return;
            }

            this.isNValidSupportEmail = false;
            this.isEmptySupportEmail = false;
            this.isNValidFinanceEmail = false;
            if (!this.companySetting.supportEmail) {
                this.isEmptySupportEmail = true;
                this.messageService.add({ severity: 'error', summary: 'Invalid data', detail: 'Please fill out the required fields' });
                return;
            }
            const phoneValid = this.checkPhoneValid();
            if (!phoneValid) {
                this.messageService.add({ severity: 'error', summary: 'Invalid data', detail: 'Please fill out the required fields' });
                return;
            }

            const invalidEmail = this.checkValidEmails(this.companySetting.supportEmail);
            const invalidFianceEmail = this.checkValidEmails(this.companySetting.financeEmail);
            // if (!this.checkValidEmails(this.companySetting.supportEmail)) {
            if (!!invalidEmail) {
                this.isNValidSupportEmail = true;
                this.messageService.add({
                    severity: 'error',
                    summary: this.translatePipe.transform('message.notAValid',
                        { value: this.transform('form.emailAddress') }),
                    detail: this.translatePipe.transform('message.isNotValidated',
                        {
                            value1: this.transform('form.email'),
                            value2: invalidEmail
                        })
                });
                this.messageService.add({ severity: 'error', summary: 'Invalid data', detail: 'Please fill out the required fields' });
                return;
            }
            this.companySetting.updateTrackingEvery = MOMENT.utc(this.companySetting.updateTrackingEveryStr, 'HH:mm').toDate();
            this.companySetting.supportResponseTime = MOMENT.utc(this.companySetting.supportResponseTimeStr, 'HH:mm').toDate();
            this.companySetting.sendAlert = MOMENT.utc(this.companySetting.sendAlertStr, 'HH:mm').toDate();
            this.companySetting.sendAt = MOMENT.utc(this.companySetting.sendAtStr, 'HH:mm').toDate();
            this.companySetting.atHours = MOMENT.utc(this.companySetting.atHoursStr, 'hh:mm A').toDate();
            this.companySetting.planAssignmentAlertTime = MOMENT.utc(this.companySetting.planAssignmentAlertTimeStr, 'HH:mm').toDate();
            this.companySetting.employeeEndTimeRemider = MOMENT.utc(this.companySetting.employeeEndTimeRemiderStr, 'HH:mm').toDate();
            if (!!invalidFianceEmail) {
                this.isNValidFinanceEmail = true;
                this.notificationService.open({
                    type: NotificationType.ERROR,
                    title: this.translatePipe.transform('message.notAValid',
                        { value: this.transform('form.emailAddress') }),
                    body: this.translatePipe.transform('message.isNotValidated',
                        {
                            value1: this.transform('form.financeEmail'),
                            value2: ''
                        })
                });
                this.messageService.add({ severity: 'error', summary: 'Invalid data', detail: 'Please fill out the required fields' });
                return;
            }
            const raw = this.agencySettingForm.getRawValue();
            raw.updateTrackingEvery = MOMENT.utc(this.companySetting.updateTrackingEveryStr, 'HH:mm').toDate();
            raw.supportResponseTime = MOMENT.utc(this.companySetting.supportResponseTimeStr, 'HH:mm').toDate();
            raw.sendAlert = MOMENT.utc(this.companySetting.sendAlertStr, 'HH:mm').toDate();
            raw.sendAt = MOMENT.utc(this.companySetting.sendAtStr, 'HH:mm').toDate();
            raw.atHours = MOMENT.utc(this.companySetting.atHoursStr, 'hh:mm A').toDate();
            raw.planAssignmentAlertTime = MOMENT.utc(this.companySetting.planAssignmentAlertTimeStr, 'HH:mm').toDate();
            raw.employeeEndTimeRemider = MOMENT.utc(this.companySetting.employeeEndTimeRemiderStr, 'HH:mm').toDate();
            if (raw.isEmployeeMissingPunchNotificationEnable == null) {
                raw.isEmployeeMissingPunchNotificationEnable = false;
            }
            if (raw.reminderEmployeeVacation == null) {
                raw.reminderEmployeeVacation = false;
            }
            if (raw.reminderEmployeeWorkingOvertime == null) {
                raw.reminderEmployeeWorkingOvertime = false;
            }

            if (raw.reminderNeedRepeat == null) {
                raw.reminderNeedRepeat = false;
            }
        
            this.processUploadFiles().subscribe(responses => {
                this.isSaving = true;
                _.assign(this.companySetting, raw);
                if (this.companySetting.jrNeedToNotifyOtherPeopleArr && this.companySetting.jrNeedToNotifyOtherPeopleArr.length > 0) {
                    const jrNeedToNotifyOtherPeopleArr = this.companySetting.jrNeedToNotifyOtherPeopleArr;
                    this.companySetting.jrNeedToNotifyOtherPeople = [];
                    jrNeedToNotifyOtherPeopleArr.forEach((item: any) => {
                        this.companySetting.jrNeedToNotifyOtherPeople.push(item.email);
                    });
                }
                if (this.companySetting && this.companySetting.otAlertSMS && this.companySetting.otAlertSMS.length > 0) {
                    this.companySetting.otAlertSMS = this.companySetting.otAlertSMS.filter(item => item.length !== 0);
                }

                if (this.smsPrice) {
                    this.smsPrice = this.smsPrice.replace('$', '');
                    this.companySetting.smsPrice = parseFloat(this.smsPrice);
                }
                if (this.smsUsageCost) {
                    this.smsUsageCost = this.smsUsageCost.replace('$', '');
                    this.companySetting.smsUsageCost = parseFloat(this.smsUsageCost);
                }
                if (this.mmsPrice) {
                    this.mmsPrice = this.mmsPrice.replace('$', '');
                    this.companySetting.mmsPrice = parseFloat(this.mmsPrice);
                }
                if (this.mmsUsageCost) {
                    this.mmsUsageCost = this.mmsUsageCost.replace('$', '');
                    this.companySetting.mmsUsageCost = parseFloat(this.mmsUsageCost);
                }
                if (this.unitPrice) {
                    this.unitPrice = this.unitPrice.replace('$', '');
                    this.companySetting.unitPrice = parseFloat(this.unitPrice);
                }

                this.companySetting.followMeList = this.followMeList;

                if (this.financeEmailList) {
                    let financeEmailStr = '';
                    for (let i = 0; i < this.financeEmailList.length; i++) {
                        if (this.formValidator.checkValidEmails(this.financeEmailList[i])) {
                            financeEmailStr = financeEmailStr + this.financeEmailList[i] + ',';
                        }
                    }
                    this.companySetting.financeEmail = financeEmailStr;
                }

                if (this.companySetting.emailSendTo) {
                    const receivers: any = this.sendToObjs.filter((item) => this.companySetting.emailSendTo.find((est) => est === item.value.username)).map(v => v.value);
                    this.companySetting.emailSendToObjs = receivers;
                    console.log('updateCompanySetting receivers: ', receivers);
                    console.log('updateCompanySetting emailSendToObjs: ', this.companySetting.emailSendToObjs);
                }
                if (this.formMode === Constants.FORM_MODE.UPDATE) {
                    this.changeHistoryHandlerEdit(this.companySetting);
                    this.companySetting.lastModifiedBy = this.authService.getCurrentUsername();
                    console.log('updateCompanySetting companySetting: ', this.companySetting);
                    // return;
                    this.companySettingServiceV2.updateCompanySetting(this.companyId, this.companySetting.id, this.companySetting)
                        .subscribe((response: any) => {
                            if (response.status === 'SUCCESS') {
                                this.notificationService.open({
                                    type: NotificationType.INFO,
                                    title: this.translatePipe.transform('success'),
                                    body: this.translatePipe.transform('CompanySettingsUpdated')
                                });
                                // this.msgs.push({ severity: 'info', summary: 'Success', detail: 'Company Settings have been updated!' });
                                this.updateIdleService();
                                setTimeout(() => this.onCancel(), 2000);
                            } else {
                                this.isSaving = false;
                                this.notificationService.open({
                                    type: NotificationType.ERROR,
                                    title: 'Error',
                                    body: response.message,
                                });
                            }
                        },
                        error => {
                            this.isSaving = false;
                            this.notifyError(error);
                        });
                } else {
                    console.log('create companySetting: ', this.companySetting);
                    // return;
                    this.companySettingServiceV2.create(this.companySetting)
                        .subscribe((response: any) => {
                            const resObj: any = response;
                            if (resObj.status === 'SUCCESS') {
                                this.changeHistoryHandler(resObj.data.id, this.companySetting);
                                this.updateIdleService();
                                this.notifySuccess(response);
                                this.onCancel();

                                this.monitoringDetailsService.monitorAction(
                                    `Punch in/out Rate Updated`,
                                    new Date(),
                                    {
                                        create_company_setting: this.authService.getCurrentLoggedInName()
                                    },
                                    'Punch in/out Rate Updated',
                                    `Punch in/out Rate Updated`,
                                    0
                                );
                            } else {
                                this.isSaving = false;
                                this.notificationService.open({
                                    type: NotificationType.ERROR,
                                    title: 'Error',
                                    body: response.message,
                                });
                            }

                        });
                }
            },
            error => {
                this.isSaving = false;
                this.notifyError(error);
            });
        } else {
            this.isSaving = false;
            const invalid = [];
            for (const name in this.agencySettingForm.controls) {
                if (this.agencySettingForm.controls[name].invalid) {
                    invalid.push(name);
                }
            }
            this.messageService.add({ severity: 'error', summary: 'Invalid data', detail: 'Please fill out the required fields' });
        }


    }

    private notifySuccess(response: any) {
        this.agencyService.notifyMessage({
            detail: response.message,
            severity: 'success'
        });
    }

    transform(key) {
        return this.translatePipe.transform(key);
    }

    private notifyError(error: any) {
        this.agencyService.notifyMessage({
            detail: error.message,
            severity: 'error'
        });
    }

    uploadSongFile(fileUpload: FileUpload) {
        if (this.localSongFile) {
            this.localSongFile.clear();
            this.localSongFile = undefined;
        } else if (fileUpload) {
            if (this.fileSizeCheck(fileUpload)) {
                this.localSongFile = fileUpload;
            }
        }
    }

    
    uploadLogofile(fileUpload: FileUpload) {
        if (this.localLogoFile) {
            this.localLogoFile.clear();
            this.localLogoFile = undefined;
        } else if (fileUpload) {
            if (this.fileSizeCheck(fileUpload)) {
                this.localLogoFile = fileUpload;
                this.loadingUploadLogofile = true;
                this.documentServicev2.uploadFile(this.localLogoFile._files[0], 'logo', this.companyId, 'Company').subscribe(res => {
                    this.loadingUploadLogofile = false;
                    const resObj: any = res;
                    this.companySetting.logoId = resObj.data.id;
                    this.companySetting.logo = new DocumentData();
                    this.companySetting.logo.fileUrl = resObj.data.fileUrl;
                    this.localLogoFile.clear();
                    // this.localLogoFile = undefined;
                }, error => {
                    this.loadingUploadLogofile = false;
                });
            }
        }
    }

    // for badge upload
    uploadbadgefile(fileUpload: FileUpload) {
        if (this.localbadgeFile) {
            this.localbadgeFile.clear();
            this.localbadgeFile = undefined;
        } else if (fileUpload) {
            if (this.fileSizeCheck(fileUpload)) {
                this.localbadgeFile = fileUpload;
                this.loadingUploadbadgefile = true;
                this.documentServicev2.uploadFile(this.localbadgeFile._files[0], 'badge', this.companyId, 'Company').subscribe(res => {
                    this.loadingUploadbadgefile = false;
                    const resObj: any = res;
                    this.companySetting.badge = resObj.data.id;
                    this.badgeFile.fileUrl = resObj.data.fileUrl;
                    this.localbadgeFile.clear();
                    // this.localbadgeFile = undefined;
                }, error => {
                    this.loadingUploadbadgefile = false;
                });
            }
        }
    }

    fileSizeCheck = (file): boolean => {
        if (file.files[0].size > 15758640) { // 15 mb = 15758640 bytes
            this.notificationService.open({
                type: NotificationType.ERROR,
                title: 'Error',
                body: 'File should not be greater than 15MB',
            });
            return false;
        }
        return true;
    }

    private processUploadFiles(): Observable<any> {
        let uploadSong$: Observable<any>;
        let uploadLogo$: Observable<any>;
        let uploadBadge$: Observable<any>;

        if (this.localSongFile) {
            uploadSong$ = this.documentServicev2.uploadFile(this.localSongFile.files[0], 'birthday_song', this.companyId, 'Company')
                .pipe(map((response: any) => {
                    this.companySetting.birthdaySong = response.data.id;
                    this.localSongFile.clear();
                }));
        }
        if (uploadSong$ && uploadLogo$ && uploadBadge$) {
            return forkJoin(uploadSong$, uploadLogo$, uploadBadge$);
        }
        if (uploadLogo$ && uploadBadge$) {
            return forkJoin(uploadLogo$, uploadBadge$);
        }

        if (uploadSong$) {
            return uploadSong$;
        }
        if (uploadLogo$) {
            return uploadLogo$;
        }
        if (uploadBadge$) {
            return uploadBadge$;
        }

        return of(null);
    }

    private initForm() {
        this.reminderOptions = [
            { label: 'Email', value: 'email' },
            { label: 'SMS', value: 'sms' },
        ];

        this.reminderTimePeriodOptions = [
            { label: 'SameDay', value: 'same_day' },
            { label: '3Days', value: '3_days' },
            { label: 'In1Week', value: 'in_1_week' },
            { label: 'In2Weeks', value: 'in_2_weeks' },
        ];

        this.otAlertType = [
            { label: 'Before Overtime', value: 'before_ot' },
            { label: 'After Overtime', value: 'after_ot' },
        ];

        this.reminderRepeatEveryOptions = [
               { label: 'Repeat every...', value: null, disabled: true, styleClass: 'text-muted' },
            { label: 'Day', value: 'day' },
            { label: 'Week', value: 'week' },
            { label: 'Month', value: 'month' },
            { label: 'Year', value: 'year' },
        ];

        this.otAlertOptions = [
            { label: 'Supervisor', value: 'Supervisor' },
            { label: 'Manager', value: 'Manager' },
            { label: 'Account', value: 'Account' },
        ];

        this.employeeMenuOptions = [
            { label: 'Employee Profile', value: 'employeeProfile' },
            { label: 'Chat Box', value: 'chatbox' },
            { label: 'Punch In/Out', value: 'punchInOut' },
            { label: 'Billing Service', value: 'billingService' },
            { label: 'Training', value: 'training' },
            { label: 'Payment', value: 'payment' },
        ];
        this.accountMenu = [
            {label: 'Transaction',
             value : (this.authService.getLoggedInUserMenuList().includes('82')) ? '82' :
             (this.authService.getLoggedInUserMenuList().includes('83')) ? '83' :
             (this.authService.getLoggedInUserMenuList().includes('84')) ? '84' : '-1'},
            {label: 'Tax Details', value: '99'},
            {label: 'TransactionDetails', value: '85'},
            {label: 'Project', value: '100'},
            {label: 'Credit/Debit Account', value: '101'},
            {label: 'Generic Compliance', value: '102'},
            {label: 'Payment Tracking', value: '103'},
            {label: 'Loan', value: '104'},
            {label: 'Follow-up Bill', value: '26'},
            {label: 'Insurance', value: '1151'}
        ];

        this.bilingMenu = [
            { label: 'Billing', value: '67' },
            { label: 'Process Payment', value: '59' },
            { label: 'Invoice', value: '65' },
            { label: 'Dispatch Billing', value: '113' },
            { label: 'Fees', value: '114' },
            { label: 'Billing Report', value: '9020' }
        ];
        this.employeeMenus = [
            { label: 'employeeName', value: '9004' },
            { label: 'punchHistory', value: '23' },
            { label: 'onTimeVsLate', value: '24' },
            { label: 'timeOffRequest', value: '25' },
            { label: 'eventSchedule', value: '46' },
            { label: 'performance', value: '44' },
            { label: 'timeCorrection', value: '45' },
            { label: 'TimeSheet$$$', value: '47' },
            { label: 'Plan/Assignment', value: '48' },
            { label: 'Note', value: '78' },
            { label: 'View Details Report', value: '1100' },
            { label: 'My Contact', value: '1111' }
        ];

        this.crmMenus = [
            { label: 'CRM', value: '30' },
            { label: 'ClientTransferred', value: '51' },
            { label: 'EVVRegistered', value: '52' },
            { label: 'Product', value: '66' },
            { label: 'Expense', value: '71' },
            { label: 'Campaign', value: '1101' },
            { label: 'Add Billing Client Log', value: '1012' },
            { label: 'Email Template', value: '1139' },
            { label: 'Client Info Account', value: '1153' }
        ];

        this.otherMenus = [
            { label: 'Dashboard', value: '111' },
            { label: 'Calendar', value: '102' },
            { label: 'Contractor', value: '56' },
            { label: 'AssignedTicket', value: '710' },
            { label: 'PaymentMethod', value: '704' },
            { label: 'ContractorInvoice', value: '703' },
            { label: 'ChatBox', value: '64' },
            { label: 'All Mail', value: '999' },
            { label: 'Deadline Reminder', value: '998' },
            { label: 'Training/Schedule', value: '1001' },
            { label: 'Training/Topic', value: '1002' },
            { label: 'Schedule Meeting', value: '36' },
            { label: 'Screen Recorder', value: '125' },
            { label: 'Equipment Check in/out', value: '1150' }
        ];

        this.userNameOption = [
            { label: 'EmailAddress', value: 'emailAddress' },
            { label: 'Optional', value: 'optional' }
        ];
        const tempDate = new Date(this.companySetting.reminderRepeatEndOn);
        this.agencySettingForm = this.formBuilder.group({
            'reminderEmployeeVacation': [this.companySetting.reminderEmployeeVacation],
            'reminderEmployeeBirthday': [this.companySetting.reminderEmployeeBirthday],
            'reminderEmployeeWorkingOvertime': [this.companySetting.reminderEmployeeWorkingOvertime],
            'reminderMethod': [this.companySetting.reminderMethod],
            'reminderTimePeriod': [this.companySetting.reminderTimePeriod],
            'reminderNeedRepeat': [this.companySetting.reminderNeedRepeat],
            'reminderRepeatEndOn': [tempDate],
            'reminderRepeatEvery': [this.companySetting.reminderRepeatEvery],
            'userNameOption': [this.companySetting.userNameOption],
            'reminderRepeatOn': [this.companySetting.reminderRepeatOn],
            'upperLeftLabel': [this.companySetting.upperLeftLabel],
            'is2ndVerificationAdminEnable': [this.companySetting.is2ndVerificationAdminEnable],
            'is2ndVerificationEmployeeEnable': [this.companySetting.is2ndVerificationEmployeeEnable],
            'employeeMenuList': [this.companySetting.employeeMenuList],
            'employeeDepartmentList': [this.companySetting.employeeDepartmentList],
            'crmMenuList': [this.companySetting.crmMenuList],
            'accountMenuList': [this.companySetting.accountMenuList],
            'billingMenuList': [this.companySetting.billingMenuList],
            'crmDepartmentList': [this.companySetting.crmDepartmentList],
            'otherMenuList': [this.companySetting.otherMenuList],
            'otherDepartmentList': [this.companySetting.otherDepartmentList],
            'birthdaySongEnable': [this.companySetting.birthdaySongEnable],
            'requiredHours': [this.companySetting.requiredHours],
            'supportResponseTime': [''],
            'requiredHoursStatus': [this.companySetting.requiredHoursStatus],
            'pinVerificationLimit': [this.companySetting.pinVerificationLimit],
            'calendarNotification': [this.companySetting.calendarNotification],
            'calendarNotificationMethod': [this.companySetting.calendarNotificationMethod],
            'maxLoginAttemptCountAdmin': [this.companySetting.maxLoginAttemptCountAdmin],
            'maxLoginAttemptCountEmployee': [this.companySetting.maxLoginAttemptCountEmployee],
            'isEmployeeMissingPunchNotificationEnable': [this.companySetting.isEmployeeMissingPunchNotificationEnable],
            'employeeMissingPunchNotificationMethod': [this.companySetting.employeeMissingPunchNotificationMethod],
            'employeeMissingPunchNotificationEmail': [this.companySetting.employeeMissingPunchNotificationEmail],
            'smsNumber': [this.companySetting.smsNumber],
            'isSeniorPunchInOutNotificationToEmergencyContactEnable': [this.companySetting.isSeniorPunchInOutNotificationToEmergencyContactEnable],
            'seniorPunchInOutNotificationToEmergencyContactMethod': [this.companySetting.seniorPunchInOutNotificationToEmergencyContactMethod],
            'mobileVisitVerification': [this.companySetting.mobileVisitVerification],
            'overtimeValidation': [this.companySetting.overtimeValidation],
            'toleranceRange': [this.companySetting.toleranceRange],
            'promptPasswordAt': [this.companySetting.promptPasswordAt],
            'overtimeAlert': [this.companySetting.overtimeAlert],
            'otAlertHour': [this.companySetting.otAlertHour],
            'otAlertMinute': [this.companySetting.otAlertMinute],
            'otAlertType': [this.companySetting.otAlertType],
            'otAlertEmail': [this.companySetting.otAlertEmail],
            'otAlertSMS': [this.companySetting.otAlertSMS],
            'sendTo': [this.companySetting.sendTo],
            'sendAt': [this.companySetting.sendAt],
            'clockInOutCondition': [this.companySetting.clockInOutCondition],
            'clientInvoiceNotification': [this.companySetting.clientInvoiceNotification],
            'updateTrackingEvery': [''],
            'sendAlert': [''],
            sendAlertWhenPunchInOut: new UntypedFormControl({ value: '', disabled: !this.enableAlertPunchInOut }),
            'supportEmail': [this.companySetting.supportEmail, []],
            'financeEmail': [this.companySetting.financeEmail, []],
            'sendActive': [this.companySetting.sendActive, []],
            'emailSendTo': [this.companySetting.emailSendTo, []],
            'dayOfWeek': [this.companySetting.dayOfWeek, []],
            'atHours': [this.companySetting.atHours != null ? new Date(this.companySetting.atHours) : null],
            planAssignmentAlertTime: new UntypedFormControl({ value: '', disabled: !this.enablePlanAssignmentTime }),
            'displayRemainingDays': [this.companySetting.displayRemainingDays],
            'clientPaymentGracePeriod': [this.companySetting.clientPaymentGracePeriod],
            'jobReportNotifySms': [this.companySetting.jobReportNotifySms],
            'jobReportNotifyEmail': [this.companySetting.jobReportNotifyEmail],
            'jrNeedToNotifyOtherPeople': [this.companySetting.jrNeedToNotifyOtherPeople],
            'jrNeedToNotifyOtherPeopleArr': [this.companySetting.jrNeedToNotifyOtherPeopleArr],
            'jrDayToSendNotify': [this.companySetting.jrDayToSendNotify],
            'jrSendNotifyEvery': [this.companySetting.jrSendNotifyEvery],
            'jrAllowExpand': [this.companySetting.jrAllowExpand],
            employeeEndTimeRemider: new UntypedFormControl({ value: '', disabled: !this.enablePlanAssignmentTime }),
            'smsQuota': [this.companySetting.smsQuota],
            'smsCount': [this.companySetting.smsCount],
            'smsQuotaFrequency': [this.companySetting.smsQuotaFrequency],
            'replySms': [this.companySetting.replySms],
            'smsPrice': [this.companySetting.smsPrice],
            'smsUsageCost': [this.companySetting.smsUsageCost],
            'smsAfterQuota': [this.companySetting.smsAfterQuota],
            'smsAvailableAfterQuota': [this.companySetting.smsAvailableAfterQuota],
            'smsCreditsUsed': [this.companySetting.smsCreditsUsed],
            'mmsCreditsUsed': [this.companySetting.mmsCreditsUsed],
            'mmsQuota': [this.companySetting.mmsQuota],
            'mmsCount': [this.companySetting.mmsCount],
            'mmsQuotaFrequency': [this.companySetting.mmsQuotaFrequency],
            'mmsPrice': [this.companySetting.mmsPrice],
            'mmsUsageCost': [this.companySetting.mmsUsageCost],
            'mmsAfterQuota': [this.companySetting.mmsAfterQuota],
            'mmsAvailableAfterQuota': [this.companySetting.mmsAvailableAfterQuota],
            'otAlertFormatHour': [this.companySetting.otAlertFormatHour],
            timeCorrectionQuota: new UntypedFormControl({ value: '', disabled: !this.enableProtestHour }),
            'timeCorrectionPer': [this.companySetting.timeCorrectionPer],
            'credits': [this.companySetting.credits],
            'autoCallMinutes': [this.companySetting.autoCallMinutes],
            'assistanceHours': [this.companySetting.assistanceHours],
            'alreadyPaid': [this.companySetting.alreadyPaid],
            'printerDevice': [this.companySetting.printerDevice],
            'residentQrCodeTemplate': [this.companySetting.residentQrCodeTemplate ? this.companySetting.residentQrCodeTemplate : 1],
            'unitPrice': [this.companySetting.unitPrice],
            'pinFacialRecognition': [this.companySetting.pinFacialRecognition],
            'punchValidation': [this.companySetting.punchValidation],
        });
        this.checkReminderNeedRepeat(this.companySetting.reminderNeedRepeat);
        this.agencySettingForm.controls['reminderNeedRepeat'].valueChanges.subscribe((value: boolean) => {
            this.checkReminderNeedRepeat(value);
        });
        //Check OT Format
        this.checkOtAlertFormat(this.otHourFormat);
        this.agencySettingForm.controls['otAlertFormatHour'].valueChanges.subscribe((value: string) => {
            this.checkOtAlertFormat(value);
        });

        const isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
        if(!isPlatformAdmin ) {
            if(this.authService.getLoggedInUserEditList().includes('108')) {
                this.enabledEmployee();
            } else {
                this.agencySettingForm.controls['pinVerificationLimit'].disable();
                this.agencySettingForm.controls['requiredHoursStatus'].disable();
                this.agencySettingForm.controls['requiredHours'].disable();
            }
        } else {
            this.enabledEmployee();
        }
    }

    isDisabled(event) {
        if (event === true && !this.disabledData) {
            this.agencySettingForm.controls['toleranceRange'].enable();
            this.agencySettingForm.controls['pinVerificationLimit'].enable();
        } else {
            this.agencySettingForm.controls['toleranceRange'].disable();
            this.companySetting.toleranceRange = null;
        }
    }

    preventInputOfPinVerifitcationLimit(event) {
        if (this.companySetting.pinVerificationLimit < 1) {
            event.preventDefault();
            this.companySetting.pinVerificationLimit = 1;
            return;
        }

        if (!this.companySetting.pinVerificationLimit) {
            this.companySetting.pinVerificationLimit = 1;
        }

        const rawString = this.companySetting.pinVerificationLimit.toString();
        if (this.companySetting.pinVerificationLimit.toString().length > 1) {
            event.preventDefault();
            const fistNumber = Number(rawString.charAt(0));
            this.companySetting.pinVerificationLimit = isNaN(fistNumber) ? 1 : fistNumber;
            return;
        }
    }


    private checkReminderNeedRepeat(value: boolean) {
        const reminderRepeatOnCtrl = this.agencySettingForm.controls['reminderRepeatOn'];
        const reminderRepeatEndOnCtrl = this.agencySettingForm.controls['reminderRepeatEndOn'];
        const reminderRepeatEveryCtrl = this.agencySettingForm.controls['reminderRepeatEvery'];
        if (value) {
            reminderRepeatOnCtrl.enable();
            reminderRepeatEndOnCtrl.enable();
            reminderRepeatEveryCtrl.enable();
            reminderRepeatOnCtrl.setValidators([Validators.required]);
            reminderRepeatOnCtrl.updateValueAndValidity();
        } else {
            reminderRepeatOnCtrl.disable();
            reminderRepeatEndOnCtrl.disable();
            reminderRepeatEveryCtrl.disable();
        }
    }

    private checkOtAlertFormat(value: string) {
        if (value === '12') {
            this.companySetting.otAlertFormatHour = '12';
        } else {
            this.companySetting.otAlertFormatHour = '24';
        }
        if (this.otHour) this.otHourStr = this.formatTime(this.otHour);
    }

    onCancel() {
        this.router.navigate(['app/company']);
    }

    onCheckOvertimeToggle(event) {
        if (event) {
            this.agencySettingForm.get('promptPasswordAt').enable();
        } else {
            this.agencySettingForm.get('promptPasswordAt').disable();
            this.agencySettingForm.get('promptPasswordAt').setValue(null);
        }
    }

    onCheckOvertimeAlertToggle(event) {
        if (event.checked === true) {
            this.agencySettingForm.get('otAlertHour').enable();
            this.agencySettingForm.get('otAlertMinute').enable();
        } else {
            this.agencySettingForm.get('otAlertHour').disable();
            this.agencySettingForm.get('otAlertMinute').disable();
            // this.agencySettingForm.get('otAlertHour').setValue(null);
            // this.agencySettingForm.get('otAlertMinute').setValue(null);
        }
    }

    private loadDepartments() {
        const cmpnyId = (this.companyId) ? this.companyId : 0;
        this.operatorService.getDepartmentsDropdownByCompanyId(cmpnyId).subscribe(res => {
            const resObj: any = res;
            this.dropDownPositions = resObj.data.map((department) => ({
                label: department.value,
                value: department.value
            }));
            // this.otAlertSMSOptions = this.dropDownPositions;
            console.log('loadDepartments otAlertSMSOptions: ', this.otAlertSMSOptions)
            this.dropDownPositions.forEach(element => {
                this.otAlertOptions.push(element);
            });
            this.otAlertSMSOptions = this.otAlertOptions;
        });
    }

    private checkValidEmails(emails: string): string {
        const emailsArr = _.split(emails, /[,;]/);
        for (let i = 0; i < emailsArr.length; i++) {
            if (!this.checkValidEmail(emailsArr[i])) {
                return emailsArr[i];
            }
        }
        return '';
    }
    private checkValidEmail(email: string): boolean {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(_.trim(email)).toLowerCase());
    }

    PositionsToDropDownChange(event) {
        this.selectedItemCount = event.value.length;
        if (this.selectedItemCount === this.dropDownPositions.length) {
            this.selectedItemCountLabel = this.translatePipe.transform('all');
        } else {
            this.selectedItemCountLabel = `${this.selectedItemCount} ` + this.translatePipe.transform('itemsSelected');
        }
    }
    

    employeeMenuToDropDownChange(event) {
        if (event.value.length === this.employeeMenus.length) {
            const x = document.getElementById('id_employeeMenu_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = this.translatePipe.transform('all');
        } else {
            const x = document.getElementById('id_employeeMenu_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = `${event.value.length} ` + this.translatePipe.transform('itemsSelected');
        }
    }

    employeeDepartmentToDropDownChange(event) {
        if (event.value.length === this.employeeDepartments.length) {
            const x = document.getElementById('id_employeeDepartment_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = this.translatePipe.transform('all');
        } else {
            const x = document.getElementById('id_employeeDepartment_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = `${event.value.length} ` + this.translatePipe.transform('itemsSelected');
        }
    }

    crmMenuToDropDownChange(event) {
        if (event.value.length === this.crmMenus.length) {
            const x = document.getElementById('id_crmMenuList_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = this.translatePipe.transform('all');
        } else {
            const x = document.getElementById('id_crmMenuList_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = `${event.value.length} ` + this.translatePipe.transform('itemsSelected');
        }
    }

    accountMenuToDropDownChange(event) {
        if (event.value.length === this.crmMenus.length) {
            const x = document.getElementById('id_accountMenuList_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = this.translatePipe.transform('all');
        } else {
            const x = document.getElementById('id_accountMenuList_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = `${event.value.length} ` + this.translatePipe.transform('itemsSelected');
        }
    }

    billingMenuToDropDownChange(event) {
        if (event.value.length === this.crmMenus.length) {
            const x = document.getElementById('id_billingMenuList_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = this.translatePipe.transform('all');
        } else {
            const x = document.getElementById('id_billingMenuList_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = `${event.value.length} ` + this.translatePipe.transform('itemsSelected');
        }
    }

    crmDepartmentToDropDownChange(event) {
        if (event.value.length === this.crmDepartments.length) {
            const x = document.getElementById('id_crmDepartment_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = this.translatePipe.transform('all');
        } else {
            const x = document.getElementById('id_crmDepartment_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = `${event.value.length} ` + this.translatePipe.transform('itemsSelected');
        }
    }

    accountDepartmentToDropDownChange(event) {
        if (event.value.length === this.crmDepartments.length) {
            const x = document.getElementById('id_accountDepartment_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = this.translatePipe.transform('all');
        } else {
            const x = document.getElementById('id_accountDepartment_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = `${event.value.length} ` + this.translatePipe.transform('itemsSelected');
        }
    }

    billingDepartmentToDropDownChange(event) {
        if (event.value.length === this.crmDepartments.length) {
            const x = document.getElementById('id_billingDepartment_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = this.translatePipe.transform('all');
        } else {
            const x = document.getElementById('id_billingDepartment_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = `${event.value.length} ` + this.translatePipe.transform('itemsSelected');
        }
    }

    otherMenuToDropDownChange(event) {
        if (event.value.length === this.otherMenus.length) {
            const x = document.getElementById('id_otherMenuList_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = this.translatePipe.transform('all');
        } else {
            const x = document.getElementById('id_otherMenuList_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = `${event.value.length} ` + this.translatePipe.transform('itemsSelected');
        }
    }

    otherDepartmentToDropDownChange(event) {
        if (event.value.length === this.otherDepartments.length) {
            const x = document.getElementById('id_otherDepartment_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = this.translatePipe.transform('all');
        } else {
            const x = document.getElementById('id_otherDepartment_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = `${event.value.length} ` + this.translatePipe.transform('itemsSelected');
        }
    }

    otAlertEmailToDropDownChange(event) {
        if (event.value.length === this.otAlertOptions.length) {
            const x = document.getElementById('id_otAlertEmail_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = this.translatePipe.transform('all');
        } else {
            const x = document.getElementById('id_otAlertEmail_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = `${event.value.length} ` + this.translatePipe.transform('itemsSelected');
        }
    }

    otAlertSMSToDropDownChange(event) {
        const result = event.value.filter(item => item.length !== 0);
        if (result.length === this.otAlertSMSOptions.length) {
            const x = document.getElementById('id_otAlertSMS_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = this.translatePipe.transform('all');
            return true;
        } else {
            const x = document.getElementById('id_otAlertSMS_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = `${result.length} ` + this.translatePipe.transform('itemsSelected');
            return true;
        }
    }

    private updateIdleService(): void {
        this.idleService.updateUserSpecificIdleTime();
    }

    deleteIpAddress(companyId: any, id: any): void {
        this.ipAddressSettingServiceV2.deleteIpAddress(companyId, id).subscribe(res => {
            if (res.status === 'SUCCESS') {
                this.locationIp = '';
                this.locationName = '';
                this.listIpAddress = res.data;
                this.length = this.listIpAddress.length;
                this.notificationService.open({
                    type: NotificationType.SUCCESS,
                    title: 'Success',
                    body: 'Delete  IP Address',
                });
            } else {
                this.notificationService.open({
                    type: NotificationType.ERROR,
                    title: 'Error',
                    body: 'Cannot delete IP Address',
                });
            }
        }, error => {
            this.notificationService.open({
                type: NotificationType.ERROR,
                title: 'Error',
                body: 'Cannot delete IP Address',
            });
        });
    }

    private updateIpAddress(companyId: any, id: any, entity: any): void {
        if (entity.locationIp === '' || entity.locationName === '') {
            this.notificationService.open({
                type: NotificationType.ERROR,
                title: 'Error',
                body: 'The IP Address cannot be empty',
            });
        } else if (this.checkDuplicateIp(entity, id)) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Duplicate IP address records . Please check again!' });
            return;
        } else {
            this.ipAddressSettingServiceV2.updateIpAddress(companyId, id, entity).subscribe(res => {
                if (res.status === 'SUCCESS') {
                    this.locationIp = '';
                    this.locationName = '';
                    this.ipAddressSettingServiceV2.findAllByCompanyId(this.companyId).subscribe(res => {
                        const resObj: any = res;
                        this.listIpAddress = resObj.data;
                        this.length = this.listIpAddress.length;
                    });
                    this.notificationService.open({
                        type: NotificationType.SUCCESS,
                        title: 'Success',
                        body: 'Update IP Address',
                    });
                } else {
                    this.notificationService.open({
                        type: NotificationType.ERROR,
                        title: 'Error',
                        body: 'Cannot update IP Address',
                    });
                }
            }, error => {
                this.notificationService.open({
                    type: NotificationType.ERROR,
                    title: 'Error',
                    body: 'Cannot update IP Address',
                });
            });
        }
    }

    private checkPhoneValid(): boolean {
        const smsCheckbox = this.notificationMethods === 'SMS' || (_.indexOf(this.notificationMethods, 'SMS') >= 0);
        if (smsCheckbox && !this.formValidator.checkValidPhone(this.smsNotification)) {
            this.messageService.add({ severity: 'error', summary: 'Not a valid phone number', detail: 'Please enter valid phone number!' });
            return false;
        }
        return true;
    }

    myCurrentIpAddress: any;
    async addMyCurrentIP() {
        this.myCurrentIpAddress = null;
        this.companySettingServiceV2.getMyCurrentIpAddress().subscribe((res: any) => {
            const objRsp: any = res;
            if (objRsp.status === 'SUCCESS') {
                this.myCurrentIpAddress = objRsp.data?.ip;
                if (this.myCurrentIpAddress) {
                    const ipArr = this.myCurrentIpAddress.split('.')
                    ipArr.splice(-1)
                    this.locationIp = ipArr.join('.') + '.0/24'
                }
                console.log('addMyCurrentIP myCurrentIpAddress: ', this.myCurrentIpAddress);
            }
            
        }, (err: any) => {
            console.log('addMyCurrentIP error: ', err);
        });
    }

    createIpAddress(): void {
        this.ipAddress = new IpAddressSetting();
        this.ipAddress.companyId = this.companyId;
        this.ipAddress.locationIp = this.locationIp;
        this.ipAddress.locationName = this.locationName;
        if (!this.locationIp || !this.locationName) {
            this.notificationService.open({
                type: NotificationType.ERROR,
                title: 'Error',
                body: 'The ' + (!this.locationIp ? 'IP Address' : 'Name/Location') + ' cannot be empty',
            });
        } else if (this.checkDuplicateIp(this.ipAddress)) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Duplicate IP address records . Please check again!' });
            return;
        } else {
            const ipArr = this.ipAddress.locationIp.split('.')
            ipArr.splice(-1)
            this.ipAddress.locationIp = ipArr.join('.') + '.0/24'

            this.ipAddressSettingServiceV2.saveIpAddress(this.companyId, this.ipAddress).subscribe(res => {
                if (res.status === 'SUCCESS') {
                    this.locationIp = '';
                    this.locationName = '';
                    this.listIpAddress = res.data;
                    this.listIpAddressTemp = JSON.stringify(this.listIpAddress);
                    this.length = this.listIpAddress.length;
                    this.notificationService.open({
                        type: NotificationType.SUCCESS,
                        title: 'Success',
                        body: 'Create  IP Address',
                    });
                } else {
                    this.notificationService.open({
                        type: NotificationType.ERROR,
                        title: 'Error',
                        body: 'Cannot create IP Address',
                    });
                }
            }, error => {
                this.notificationService.open({
                    type: NotificationType.ERROR,
                    title: 'Error',
                    body: 'Cannot delete IP Address',
                });
            });
        }
    }

    enabledEmployee() {
        this.agencySettingForm.controls['pinVerificationLimit'].enable();
        this.agencySettingForm.controls['requiredHoursStatus'].enable();
        this.agencySettingForm.controls['requiredHours'].enable();
    }

    showHistory(type: string) {
        switch (type) {
            case 'notify-history':
                this.historyHeader = 'Agency Settings History';
                this.historyType = 'notify';
                break;
            case 'misc-history':
                this.historyHeader = 'Misc. Settings History';
                this.historyType = 'misc';
                break;
            case 'punch_history':
                this.historyHeader = 'Punch History';
                this.historyType = 'punch_history';
                break;
            case 'sms':
                this.historyHeader = 'SMS';
                this.historyType = 'sms';
                break;
            case 'mms':
                this.historyHeader = 'MMS';
                this.historyType = 'mms';
                break;
            case 'companyLogo':
                this.historyHeader = 'Company Logo';
                this.historyType = 'companyLogo';
                break;
            case 'plan':
                this.historyHeader = 'Plan History';
                this.historyType = 'plan_history';
                break;
            case 'creditSetup':
                this.historyHeader = 'CreditSetup';
                this.historyType = 'credit_setup';
                break;
        }
        this.historyShow = true;
    }


    fillAllEmployeeEmail() {
        this.fillAllEmployeeEmailLoading = true;
        const options: any = {};
        options.companyId = this.companyId;
        options.status = 1;
        
        this.employeeService.searchEmployee(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.employees = resObj.data.content;
                this.employees.forEach((emp: Employee) => {
                    if (emp.email) {
                        let empkey = emp.fullName + ': ' + emp.email;
                        const obj = { key: empkey, email: emp.email }
                        this.suggestionsForBaseEmailFullList.push(obj);
                    }
                });
                console.log('fillAllEmployeeEmail suggestionsForBaseEmailFullList', this.suggestionsForBaseEmailFullList);
                this.fillAllEmployeeEmailLoading = false;
            }
        });
    }

    filterEmailProviderForTo(event) {
        this.suggestionsForBaseEmail = [];
        const query = event.query;
       
        (this.suggestionsForBaseEmailFullList).forEach((item) => {
          if (item && item.email) {
            if (item.key.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
              this.suggestionsForBaseEmail.push(item);
            }
          }
        });
    
        let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    
        if(event.query.match(mailformat)){     
            let newItem={
              key:query,
              email:query
            }
            this.suggestionsForBaseEmail= [];
            this.suggestionsForBaseEmail.push(newItem);
        }
    }

    isNumber(evt) {
        const charCode = (evt.which) ? evt.which : evt.keyCode;
        const val = evt.target?.value;
        if (val.length > 0) {
          if ((48 <= charCode && charCode <= 57) || charCode === 46)  {
            return true;
          }
        } else {
          if (48 <= charCode && charCode <= 57) {
            return true;
          }
        }
        return false;
    }

    smsPrice = '$0.00';
    smsUsageCost = '$0.00';
    mmsPrice = '$0.00';
    mmsUsageCost = '$0.00';
    unitPrice = '$0.00';

    formatData(data) {
        let temp = '';
        switch (data) {
          case 'smsPrice':
            temp = this.smsPrice.replace('$', '');
            if ((Number(temp))) {
                this.smsPrice = '$' + Number(temp).toFixed(2);
            } else {
                this.smsPrice = '$0.00';
            }
            break;
          case 'smsUsageCost':
            temp = this.smsUsageCost.replace('$', '');
            if ((Number(temp))) {
                this.smsUsageCost = '$' + Number(temp).toFixed(2);
            } else {
                this.smsUsageCost = '$0.00';
            }
            break;
          case 'mmsPrice':
            temp = this.mmsPrice.replace('$', '');
            if ((Number(temp))) {
                this.mmsPrice = '$' + Number(temp).toFixed(2);
            } else {
                this.mmsPrice = '$0.00';
            }
            break;
          case 'mmsUsageCost':
            temp = this.mmsUsageCost.replace('$', '');
            if ((Number(temp))) {
                this.mmsUsageCost = '$' + Number(temp).toFixed(2);
            } else {
                this.mmsUsageCost = '$0.00';
            }
            break;
          case 'unitPrice':
            temp = this.unitPrice.replace('$', '');
            if ((Number(temp))) {
                this.unitPrice = '$' + Number(temp).toFixed(2);
            } else {
                this.unitPrice = '$0.00';
            }
            break;
          default:
            break;
        }
    }

    onChangeOtAlert(event) {
        if (event) {
            this.otHourStr = this.formatTime(event);
        }
        this.convertOtHour(this.otHourStr);
        console.log('event', event)
    }

    formatTime(timeString) {
        return new Date(timeString).toLocaleTimeString('en-US', {hour12:false,hour:'numeric',minute:'numeric'});
    }

    convertOtHour(otHour) {
        if (!otHour) {
            return;
        }
        const [hourString, minute] = otHour.split(":");
        this.agencySettingForm.get('otAlertHour').setValue(hourString);
        this.companySetting.otAlertHour = hourString;
        this.agencySettingForm.get('otAlertMinute').setValue(minute);
        this.companySetting.otAlertMinute = minute;        
    }

    convertTimeStr() {
        let hour = this.companySetting.otAlertHour.toString();
        if (this.companySetting.otAlertHour < 10) {
            hour = '0'+this.companySetting.otAlertHour;
        } else if (this.companySetting.otAlertHour === 24) {
            hour = '00';
        }
        let minutes = this.companySetting.otAlertMinute.toString();
        if (this.companySetting.otAlertMinute < 10) {
            minutes = '0'+this.companySetting.otAlertMinute;
        }
        return hour + ':' + minutes;
    }

    playAudio(url) {
        this.audioFile = new Audio();
        this.audioFile.src = url;
        this.audioFile.onloadedmetadata = () => {
          // Loop audio
          this.audioFile.addEventListener('ended', () => {
            this.audioFile.currentTime = 0;
            this.audioFile.play();
          }, false);
          const playPromise = this.audioFile.play();
          playPromise.catch((e)  => {
            // do something
            console.error(e);
            if (this.audioFile) {
              this.audioFile.play();
            }
          });
        };
        this.muteVolume = true;
        this.showVolume = false;
    }

    stopAudio() {
        if (!this.audioFile) {
          return;
        }
        this.audioFile.currentTime = 0;
        this.audioFile.pause();
        this.muteVolume = false;
        this.showVolume = true;
    }

    afterQuotaChange(type) {
        if (type === 'SMS') {
            this.companySetting.smsAvailableAfterQuota = null;
        }
        if (type === 'MMS') {
            this.companySetting.mmsAvailableAfterQuota = null;
        }
    }

    getItemAtIndex(index: number) {
        return this.getNotifyFor[index];
    }

    addOperrTelCallForwarding() {
        const index = this.followMeList.length + 1;
        this.agencySettingForm.addControl('followMe'+ index, new UntypedFormControl({ value: ''}));
        const followMe = {
            phone: ''
        };
        this.followMeList.push(followMe);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.stopAudio();
    }

    openCreditSetting() {
        if (this.isSuperAdmin()) {
            this.creditDollarValue = this.companySetting.creditDollarValue;
            this.creditValue = this.companySetting.creditValue;
            this.creditSmsValue = this.companySetting.creditSmsValue;
            this.creditMmsValue = this.companySetting.creditMmsValue;
            this.creditSettingDialog = true;
        }
    }

    submitCreditSetting() {
        if (!this.companySetting.id) {
            this.companySetting.creditDollarValue = this.creditDollarValue;
            this.companySetting.creditValue = this.creditValue;
            this.companySetting.creditSmsValue = this.creditSmsValue;
            this.companySetting.creditMmsValue = this.creditMmsValue;
            this.creditSettingDialog = false;
            return;
        }
        this.companySettingServiceV2.updateCreditSettingAll({
            creditDollarValue: this.creditDollarValue,
            creditValue: this.creditValue,
            creditSmsValue: this.creditSmsValue,
            creditMmsValue: this.creditMmsValue,
            id: this.companySetting.id,
            lastModifiedBy: this.authService.getCurrentUsername()
        }).subscribe(() => {
            this.companySetting.creditDollarValue = this.creditDollarValue;
            this.companySetting.creditValue = this.creditValue;
            this.companySetting.creditSmsValue = this.creditSmsValue;
            this.companySetting.creditMmsValue = this.creditMmsValue;
            this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Update Credit Setup successfully',
            });
            this.creditSettingDialog = false;
        }, (error) => {
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to update Credit Setup',
            });
        });
    }

    isSuperAdmin() {
        const role = this.authService.getRoleLevel();
        return role === 1 || role === 2;
    }

    getPrinterDevice() {
        checkAvailablePrinters(
            (deviceList: any[]) => {
              if (deviceList.length > 0) {
                this.currentPrinterDevices = deviceList;
                this.printerDevices = deviceList.map((device) => ({
                    value: device.name,
                    label: device.name
                }));
                console.log('Printer device found!', this.printerDevices);
              } else {
                console.error('No printer device found!');
              }
            },
            () => {
                console.error('Error while getting printer device!');
            });
    }

    testPrinter() {
        if (!this.companySetting.printerDevice) {
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Please select printer device',
            });
            return;
        }
        const printer = this.currentPrinterDevices.find((device) => this.companySetting.printerDevice === device.name);
        if (!printer) {
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Printer device not found',
            });
            return;
        }
        let zpl = `
            ^XA
            ^FO50,50^A0N,50,50^FDPrint Test!^FS
            ^XZ
        `;
        printer.send(zpl, () => {
            this.messageService.add({
                severity: 'success',
                summary: 'Print Test',
                detail: 'Print Test successfully!'
            });
        },
        () => {
            this.messageService.add({
                severity: 'error',
                summary: 'Print Test',
                detail: 'Print Test fail!'
            });
        });
    }

    myvalue: any = ''

    getValueChips(e) {
        this.myvalue = e?.target?.value
    }


    validateBeforeAddEmail(event: any, target?: any) {
        if (!this.formValidator.checkValidEmails(event.value)) {
          const errorValidEmail = "Email Address: " + event.value +  " is not valid!";
          this.messageService.add({ severity: 'error', summary: 'Error', detail: errorValidEmail });
    
          if (this.financeEmailList) {
            this.financeEmailList = this.financeEmailList.filter(v => v !== event.value);
          }
          return;
        }
    }
    
    actionAddEmail(target) {

        if (!this.formValidator.checkValidEmails(this.myvalue)) {
          const errorValidEmail = "Email Address: " + this.myvalue +  " is not valid!";
          this.messageService.add({ severity: 'error', summary: 'Error', detail: errorValidEmail });
          return;
        }
    
        if (!this.financeEmailList) {
          this.financeEmailList = [];
        }
    
        if (this.myvalue) {
          this.financeEmailList.push(this.myvalue)
        }
    
        target.inputViewChild.nativeElement.value = '';
    }

    sendToDropDownChange(event) {
        console.log('SendToDropDownChange event', event);
    }
}
