import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { Employee } from '../model/employee.model';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Page } from '../../shared/model/page.model';
import { environment, environmentLocal } from '../../../environments/environment';
import { EmployeeNotifcationSetting } from '../model/employee-setting/employee-notifcation-setting.model';
import { AuthService } from '../../shared/service/auth/auth.service';
import { WeekDateRange } from '../../shared/model/custom-ui.model';
import { SingleResponse } from '../../shared/model/response';
import { TimeSheetRespone } from '../model/timesheet.model';


import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContactInfoMapping } from '../model/contact-info-mapping';
import { env } from 'process';

@Injectable({ providedIn: 'root' })
export class EmployeeService extends AbstractService<Employee> {
  // private docApiUrl = `${environment.server_ip}/${Constants.ROUTES.FILEUPLOAD}`;

  employeeRateUrl = `${environment.v2_server_ip}/${Constants.ROUTES.EMPLOYEE}/rate`;
  public showOutstandingBalancePayDialog = false;
  public showRegisterChildAccountDialog = false;
  public invoiceWarningDialog = false;
  public balanceWaringDialog = false;
  public showConfirmAutoPayReminderDialog = false;
  public payUnPaidInvoiceDialog = false;
  constructor(private http: HttpClient, private authService: AuthService) {
    super(http, Constants.ROUTES.EMPLOYEE, '');
  }

  filterByCompanyAndAgencyForManager(params: any): Observable<Page<Employee>> {
    return this._http.get<Page<Employee>>(this.baseUrlV2 + Constants.URI.EMPLOYEE.FILTERBYCOMPANYANDAGENCYMANAGER, { params: params }).pipe(map(resp => resp));
  }

  getEmployeeByIdCustom(id: number): Observable<Employee[]> {
    // return this._http.get<Employee[]>(`http://localhost:8004/api/v2/employee/${id}`).pipe(map(resp => resp));
    return this._http.get<Employee[]>(`${this.baseUrlV2}${id}`).pipe(map(resp => resp));
  }

  // getEmployeeByIdCustom(id: number): Observable<Employee[]> {
  //   return this._http.get<Employee[]>(`${this.baseUrl}byId/${id}`).pipe(map(resp => resp));
  // }

  getAllByAgency(agencyId: number): Observable<Employee[]> {
    return this._http.get<Employee[]>(`${this.baseUrlV2}byagency/${agencyId}`).pipe(map(resp => resp));
  }

  getEmployeeListByPage(params: any): Observable<Page<Employee>> {
    return this._http.get<Page<Employee>>(this.baseUrlV2 + Constants.URI.EMPLOYEE.LISTALLBYPAGE, { params: params }).pipe(map(resp => resp));
  }

  byAgencyCompany(params: any): Observable<Page<Employee>> {
    return this._http.get<Page<Employee>>(this.baseUrlV2 + Constants.URI.EMPLOYEE.BYAGENCYCOMPANY, { params: params }).pipe(map(resp => resp));
  }

  byAgencyAndCompany(params: any): Observable<Page<Employee>> {
    return this._http.post<any>(this.baseUrlV2 + Constants.URI.EMPLOYEE.BY_AGENCY_AND_COMPANY, params).pipe(map(resp => resp));
  }

  getEmployeeListForCallout(params: any): Observable<Page<Employee>> {
    return this._http.get<Page<Employee>>(this.baseUrlV2 + "findAllWithoutPageable", { params: params }).pipe(map(resp => resp));
  }

  getDepartmentList(): Observable<any> {
    return this._http.get<any>(this.baseUrlV2 + Constants.URI.EMPLOYEE.DEPARTMENTLIST, {}).pipe(map(resp => resp));
  }
  getCreatedByList(params): Observable<any> {
    return this._http.get<any>(`${this.baseUrlV2}findAllCreatedBy`, { params: params }).pipe(map(resp => resp));
  }

  generatePin(): Observable<any> {
    return this._http.get<any>(this.baseUrlV2 + Constants.URI.EMPLOYEE.GENERATEPIN, {}).pipe(map(resp => resp));
  }

  generatePinByCompany(companyId: number): Observable<any> {
    return this._http.get<any>(this.baseUrlV2 + Constants.URI.EMPLOYEE.GENERATEPIN + '/' + companyId, {}).pipe(map(resp => resp));
  }

  getQRCodeByEmployeeId(employeeId: number): Observable<any> {
    return this._http.get<any>(`${this.baseUrlV2}generateQrCode/${employeeId}`).pipe(map(resp => resp));
  }

  sendSMSQRCode(fileLink: string, employeeId: number): Observable<any> {
    return this._http.get(`${this.baseUrlV2}sendQrCodeToSms/${employeeId}`, { params: { fileUrl: fileLink } }).pipe(map(resp => resp));
  }

  sendEmailQRCode(fileLink: string, employeeId: number): Observable<any> {
    return this._http.get(`${this.baseUrlV2}sendQrCodeToEmail/${employeeId}`, { params: { fileUrl: fileLink } }).pipe(map(resp => resp));
  }

  getEmployeeSettings(data): Observable<EmployeeNotifcationSetting> {
    return this._http.post<EmployeeNotifcationSetting>(`${this.baseUrlV2}${Constants.ROUTES.NOTIFCATIONSETTING}/search`, data).pipe(map(resp => resp));
  }

  getEmployeeSettingById(id): Observable<EmployeeNotifcationSetting> {
    return this._http.get<EmployeeNotifcationSetting>(`${this.baseUrlV2}${Constants.ROUTES.NOTIFCATIONSETTING}/${id}`).pipe(map(resp => resp));
  }

  getByUsername(params): Observable<EmployeeNotifcationSetting> {
    return this._http.get<EmployeeNotifcationSetting>(this.baseUrlV2 + 'findByUserName', { params: params }).pipe(map(resp => resp));
  }

  getEmployeeList(): Observable<Employee[]> {
    return this._http.get<Employee[]>(this.baseUrlV2 + Constants.URI.EMPLOYEE.LISTALL).pipe(map(resp => resp));
  }

  getEmployeeListByCompanyId(companyId: number, status?: number): Observable<Employee[]> {
    let query = `?`;
    if  (companyId) {
      query += `companyId=${companyId}`;
    }
    if (status) {
      query += `&status=${status}`;
    }
    return this._http.get<Employee[]>(this.baseUrlV2 + Constants.URI.EMPLOYEE.DROPDOWNLIST + query).pipe(map(resp => resp));
  }

  getEmployeeListFilterCompanyId(companyId: number): Observable<Employee[]> {
    return this._http.get<Employee[]>(this.baseUrlV2 + Constants.URI.EMPLOYEE.DROPDOWNLIST + `?companyId=${companyId}`).pipe(map(resp => resp));
  }

  findEmpForDropDown(): Observable<Employee[]> {
    return this._http.get<any>(this.baseUrlV2 + Constants.URI.EMPLOYEE.DROPDOWNLISTALL).pipe(map(resp => resp));
  }

  saveEmployeeSetting(notificationData: any): Observable<any> {
    if (notificationData.frequency && notificationData.frequency.length > 0) {
      notificationData.frequency = notificationData.frequency.toString();
    } else {
      notificationData.frequency = null;
    }
    return this._http.post<any>(`${this.baseUrlV2}${Constants.ROUTES.NOTIFCATIONSETTING}`, notificationData).pipe(map(resp => resp));
  }

  getAllEmpByPosition(params: any): Observable<Employee[]> {
    return this._http.get<any>(this.baseUrlV2 + Constants.URI.EMPLOYEE.LISTBYPOSITION, { params: params }).pipe(map(resp => resp));
  }

  // updateEmployeeAndRelatedTable(payload: any): Observable<Employee[]> {
  //   return this._http.put<any>(this.baseUrl + Constants.URI.EMPLOYEE.UPDATEEMPLOYEEANDRELATEDTABLE, payload).pipe(map(resp => resp));
  // }

  // createEmployeeAndRelatedTable(payload: any): Observable<Employee[]> {
  //   return this._http.post<any>(this.baseUrl + Constants.URI.EMPLOYEE.CREATEPLOYEEANDRELATEDTABLE, payload).pipe(map(resp => resp));
  // }

  public createExtensionOnNewServer(employee: Employee): Observable<any> {
    return this.http.post<any>(this.sipUrl, {
      name: employee.firstName,
      extension: employee.extension,
      outboundcid: employee.phone,
      devinfo_secret: employee.firstName + employee.extension,
      did_number: employee.did,
      did_name: employee.did
    }).pipe(map(resp => resp));
  }

  findTimeSheet(employeeId: number, weekDateRange: WeekDateRange): Observable<SingleResponse<TimeSheetRespone>> {

    const requestParams = {
      start: moment(weekDateRange.start).format('YYYY-MM-DD'),
      end: moment(weekDateRange.end).format('YYYY-MM-DD')
    };
    return this._http.get<SingleResponse<TimeSheetRespone>>(`${this.baseUrlV2}${employeeId}/timesheet`, { params: requestParams });

  }

  filterTimeSheet(options: any): Observable<SingleResponse<TimeSheetRespone>> {
    options.toDate = moment(options.toDate).toISOString(true);
    options.fromDate = moment(options.fromDate).toISOString(true);
    const reqUrl = `${this.baseUrlV2}filter/timesheet`;
    return this._http.post<SingleResponse<TimeSheetRespone>>(reqUrl, options).pipe(map(resp => resp));
  }

  generatePdfFile(id, firstName, lastName, start, end, timeSheetPDFModel): Observable<any> {
    return this._http.post<any>(`${environment.server_ip}/pdfgenerate/timesheetPdf/${id}/${firstName}/${lastName}/${start}/${end}`, timeSheetPDFModel).pipe(map(resp => resp));
  }

  sendFileToMail(start, end, timeSheetPDFModel): Observable<any> {
    return this._http.post<any>(`${environment.server_ip}/pdfgenerate/timesheetPdfSendMail/${start}/${end}`, timeSheetPDFModel).pipe(map(resp => resp));
  }

  getEmployeeEventSchedule(options: any, downloadParams?: any) {
    const toSendOptions: any = {
      visitType: options.visitType,
      searchKey: options.searchKey,
      employeeId: options.employeeId
    };

    if (options.eventId !== null && options.eventId > 0) {
      toSendOptions.eventId = parseInt(options.eventId, 10);
    }

    if (options.dateRange) {
      toSendOptions.startDate = options.dateRange[0];
      toSendOptions.endDate = options.dateRange[1];
    }
    let toSendQueryParams: any = {
      page: options.page,
      size: options.size
    };
    let reqUrl: any = '';
    const userCurrentUserRole = this.authService.getRoleLevel();
    if (downloadParams) {
      if (userCurrentUserRole === 7) {
        reqUrl = this.baseUrlV2 + Constants.URI.EMPLOYEE.EVENTSCHEDULE + '/filter/report';
      } else {
        reqUrl = `${environment.server_ip}/${Constants.ROUTES.CRM.EVENT}/assignto/filter/report`;
        toSendOptions.employeeId = null;
        toSendOptions.adminId = options.employeeId;
      }
      toSendQueryParams = { type: downloadParams.type };
    } else {
      if (userCurrentUserRole === 7) {
        reqUrl = this.baseUrlV2 + Constants.URI.EMPLOYEE.EVENTSCHEDULE + '/filter';
      } else {
        reqUrl = `${environment.server_ip}/${Constants.ROUTES.CRM.EVENT}/assignto/filter`;
        toSendOptions.employeeId = null;
        toSendOptions.adminId = options.employeeId;
      }
    }
    return this._http.post<any>(reqUrl, toSendOptions, { params: toSendQueryParams }).pipe(map(resp => resp));
  }

  isAccountNameAvail(accountName: any) {
    const URL = this.baseUrlV2 + 'isAccountNameAvail';
    const option = { accountName: accountName };
    return this._http.get(URL, { params: option });
  }

  isEmailAvail(email: string, companyId: any) {
    const URL = this.baseUrlV2 + '/email-exist';
    const option = {
      email: email,
      companyId: companyId ? companyId : null
    };
    return this._http.post(URL, option);
  }

  isPinNumberAvailable(pin: any, companyId: any) {
    const URL = this.baseUrlV2 + 'isPinNumberAvailable/' + companyId;
    const option = { pin: pin };
    return this._http.get(URL, { params: option });
  }

  updateEmployeePin(pin: any, companyId: any, employeeId: any) {
    const URL = this.baseUrlV2 + 'updatePin/' + companyId + '/' + employeeId;
    const option = { pin: pin };
    return this._http.get(URL, { params: option });
  }

  isPinIsAvailable(pin: any) {
    const URL = this.baseUrlV2 + `isPinIsAvailable/${pin}`;

    return this._http.get(URL);
  }


  searchPin(pin: any) {
    const URL = this.baseUrlV2 + `search?pin=${pin}`;
    return this._http.get(URL);
  }

  getEmployeeEventScheduleById(id: any) {
    return this._http.get<any>(this.baseUrlV2 + 'event/' + id).pipe(map(resp => resp));
  }

  getProductList(params?: any): Observable<Page<Employee>> {
    return this._http.get<Page<Employee>>(`${environment.server_ip}/${Constants.ROUTES.PRODUCT}`, { params: params }).pipe(map(resp => resp));
  }

  authenticateAdmin(data) {
    return this._http.post<any>(this.baseUrlV2 + 'admin_verification', data).pipe(map(resp => resp));
  }

  searchChanges(params: any): Observable<Page<any>> {
    return this._http.post<Page<any>>(`${this.baseUrlV2}history/search`, params).pipe(map(resp => resp));
  }

  getEmployeeAvoidOT(options: any): Observable<any> {
    const param = { page: options.page, size: options.size };
    const reqUrl = `${this.baseUrlV2}avoid-overtime`;
    return this._http.post<any>(reqUrl, options, { params: param }).pipe(map(resp => resp));
  }

  sendingPotentialReport(param: any): Observable<any> {
    return this._http.post<Page<any>>(`${this.baseUrlV2}avoid-overtime/report`, param).pipe(map(resp => resp));
  }

  sendingPotentialEmployeeReport(param: any): Observable<any> {
    return this._http.post<Page<any>>(`${this.baseUrlV2}avoid-overtime/employee-report`, param).pipe(map(resp => resp));
  }

  getOvertimeDashboard(options: any): Observable<any> {
    const param = { page: options.page, size: options.size };
    const reqUrl = `${this.baseUrlV2}overtime-dashboard`;
    return this._http.post<any>(reqUrl, options, { params: param }).pipe(map(resp => resp));
  }


  changePassword(entity: any): Observable<any> {
    return this._http.put<any>(`${this.baseUrlV2}${Constants.URI.ADMIN.CHANGE_PASSWORD}`, entity).pipe(map(res => res));
  }

  validateCurrentPassword(entity: any): Observable<any> {
    return this._http.post<any>(this.baseUrlV2 + 'validate-password', entity).pipe(map(res => res));
  }

  exportEmployee(param: any): Observable<any> {
    return this._http.get<Page<any>>(`${this.baseUrlV2}export-employees`, { params: param }).pipe(map(resp => resp));
  }

  exportPdf(data?: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}exportPdf`, data).pipe(map(resp => resp));
  }

  exportPhoneDirectorPdf(data?: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}exportPhoneDirectorPdf`, data).pipe(map(resp => resp));
  }

  findAllPinNumberWithoutPageable(): Observable<Page<Employee>> {
    return this._http.get<Page<Employee>>(this.baseUrlV2 + Constants.URI.EMPLOYEE.FIND_ALL_PIN_NUMBER).pipe(map(resp => resp));
  }

  findAllByDateOfBirth(params): Observable<any> {
    return this._http.get<Page<Employee>>(`${this.baseUrlV2}birthday`, { params: params }).pipe(map(resp => resp));
  }

  findAllByEmployeeStartDate(params?: any): Observable<any> {
    return this._http.get<Page<Employee>>(`${this.baseUrlV2}aniversary`, { params: params }).pipe(map(resp => resp));
  }

  findAllAnniversaryByAdmin(companyId: any): Observable<any> {
    return this._http.get<Page<Employee>>(`${this.baseUrlV2}aniversary/` + companyId).pipe(map(resp => resp));
  }

  getEmployeeByIds(id): Observable<any> {
    return this._http.get<Page<Employee>>(`${this.baseUrlV2}${id}`).pipe(map(resp => resp));
  }

  updateAgreement(id: any) {
    const url  = this.baseUrlV2 + id + '/agreement';
    return this._http.post<any>(url, null).pipe(map(resp => resp));
  }

  updateOverTimeIncrease(data) {
    return this._http.post<any>(this.baseUrlV2 + 'ot-increase', data).pipe(map(resp => resp));
  }

  getOverTimeIncrease(id: any) {
    return this._http.get<any>(this.baseUrlV2 + 'ot-increase/' + id).pipe(map(resp => resp));
  }

  dashboard(id: any): Observable<any> {
    return this._http.get<any>(`${this.baseUrlV2}dashboard/` + id).pipe(map(resp => resp));
  }

  // ==========v2=================
  filterByCompany(params: any): Observable<Page<Employee>> {
    return this._http.get<Page<Employee>>(this.baseUrlV2 + Constants.URI.EMPLOYEE.FILTERBYCOMPANY, { params: params }).pipe(map(resp => resp));
  }

  filterByCompanyNew(params: any): Observable<Page<Employee>> {
      return this._http.post<Page<Employee>>(this.baseUrlV2 + Constants.URI.EMPLOYEE.FILTERBYCOMPANYNEW, params).pipe(map(resp => resp));
  }

  getProfileImage(id: number) {
    return this._http.get<any>(`${environment.v2_server_backend}/notification/document/${id}`).pipe(map(resp => resp));
  }


  createEmployeeAndRelatedTable(payload: any): Observable<Employee[]> {
    // return this._http.post<any>(`http://localhost:8004/api/v2/employee/`, payload).pipe(map(resp => resp));
    return this._http.post<any>(this.baseUrlV2, payload).pipe(map(resp => resp));
  }

  updateEmployeeAndRelatedTable(payload: any): Observable<Employee[]> {
    // return this._http.put<any>(`http://localhost:8004/api/v2/employee/`, payload).pipe(map(resp => resp));
    return this._http.put<any>(this.baseUrlV2, payload).pipe(map(resp => resp));
  }

  verifySsn(payload: any): Observable<any> {
    //return this._http.post<any>('http://localhost:8004/api/v2/employee/verifySsn', payload).pipe(map(resp => resp));
    return this._http.post<any>(this.baseUrlV2 + 'verifySsn', payload).pipe(map(resp => resp));
  }

  verifyEmail(payload: any): Observable<any> {
      return this._http.post<any>(this.baseUrlV2 + 'verifyEmail', payload).pipe(map(resp => resp));
  }

  getAllsDropdown(params: any): Observable<Employee[]> {
    // return this._http.get<any>(`http://localhost:8004/api/v2/employee/` + Constants.URI.EMPLOYEE.DROPDOWNLIST, { params: params }).pipe(map(resp => resp));
    return this._http.get<any>(this.baseUrlV2 + Constants.URI.EMPLOYEE.DROPDOWNLIST, { params: params }).pipe(map(resp => resp));
  }

  searchEmployee(payload: any): Observable<Employee[]> {
    // return this._http.post<any>(`http://localhost:8004/api/v2/employee/` + 'search', payload).pipe(map(resp => resp));
    return this._http.post<any>(this.baseUrlV2 + 'search', payload).pipe(map(resp => resp));
  }

  searchScheduler(payload: any): Observable<Employee[]> {
    // return this._http.post<any>(`http://localhost:8004/api/v2/employee/scheduler/` + 'search', payload).pipe(map(resp => resp));
    return this._http.post<any>(this.baseUrlV2 + 'scheduler/search', payload).pipe(map(resp => resp));
  }

  searchEmployeeGet(params: any): Observable<Page<Employee>> {
    return this._http.get<Page<Employee>>(this.baseUrlV2 + 'search', { params: params }).pipe(map(resp => resp));
  }

  getAllEmployee() {
    return this._http.get<any>(this.baseUrlV2).pipe(map(resp => resp));
  }

  createEmloyeeAbsence(payload: any): Observable<Employee[]> {
    return this._http.post<any>(this.baseUrlV2 + `${payload.employeeId}/absence`, payload).pipe(map(resp => resp));
  }

  updateEmloyeeAbsence(payload: any): Observable<Employee[]> {
    return this._http.put<any>(this.baseUrlV2 + `${payload.employeeId}/absence/`, payload).pipe(map(resp => resp));
  }

  getEmployeeAbsence(employeeId: number) {
    return this._http.get<any>(this.baseUrlV2 + `${employeeId}/absence`,).pipe(map(resp => resp));
  }

  getAllAbsence(options: any): Observable<Page<any>> {
    // return this._http.get<Page<any>>(`http://localhost:8004/api/v2/employee/absence/search`, {
    //   params: options
    // }).pipe(map(resp => resp));
    return this._http.get<Page<any>>(`${this.baseUrlV2}absence/search`, {
      params: options
    }).pipe(map(resp => resp));
  }

  getAbsenceNotification(params) {
    return this._http.get<any>(`${this.baseUrlV2}notification`, { params: params }).pipe(map(resp => resp));
  }

  deleteAbsenceNotification(options) {
    return this._http.post<any>(`${this.baseUrlV2}notification/delete`, options).pipe(map(resp => resp));
  }

  setInactiveEmployee(employeeId: number): Observable<any> {
    return this._http.get<any>(`${this.baseUrlV2}terminated/${employeeId}`).pipe(map(resp => resp));
  }


  saveHistoryDemographicData(data) {
    return this._http.post(`${this.baseUrlV2}history`, data).pipe(map(resp => resp));
  }

  generateReport(data) {
    return this._http.post(`${this.baseUrlV2}absence/report-print`, data).pipe(map(resp => resp));
  }

  getAdmins(companyId: any) {
    return this._http.get<any>(this.baseUrlV2 + Constants.URI.EMPLOYEE.LISTALLBYPAGE).pipe(map(res => res));
  }

  updateEmployeeOnlineStatus(id: any, status: number) {
    const url  = this.baseUrlV2 + id + '/online-status/' + status;
    return this._http.post<any>(url, null).pipe(map(resp => resp));
  }

  getNumberOfCurrentUserOnline(companyId: any) {
    return this._http.get<any>(this.baseUrlV2 + 'employee-online/' + companyId ).pipe(map(res => res));
  }

  getEmployeeDropdownToGuest(option: any): Observable<Employee[]> {
    return this._http.post<any>(this.baseUrlV2 + Constants.URI.EMPLOYEE.EMPLOYEE_TO_GUEST, option).pipe(map(resp => resp));
  }

  updateMigratedStatus(id: any) {
    const url  = this.baseUrlV2 + '/migrated-employee/' + id;
    return this._http.get<any>(url);
  }

  unblockUser(data) {
    return this._http.get(`${this.baseUrlV2}${data.id}/status/${data.status}`);
  }

  saveEmployeeRate(payload) {
    return this._http.post<any>(this.employeeRateUrl, payload).pipe(map(resp => resp));
  }
 
  updateEmployeeRate(id, payload) {
    return this._http.put<any>(`${this.employeeRateUrl}/${id}`, payload).pipe(map(resp => resp));
  }

  getEmployeeRate(options) {
    return this._http.post<any>(`${this.employeeRateUrl}/search-by-range`, options).pipe(map(resp => resp));
  }

  getAllByCompanyIdWithoutPage(params: any): Observable<Page<Employee>> {
    return this._http.get<Page<Employee>>(this.baseUrlV2 + Constants.URI.EMPLOYEE.FIND_ALL_WITHOUT_PAGE, { params: params }).pipe(map(resp => resp));
  }

  saveDocumentCount(body) {
      return this._http.put<any>(this.baseUrlV2 + 'update-document-count/' , body).pipe(map(res => res));
  }

  updateReminder(payload) {
    return this._http.put<any>(this.baseUrlV2 + 'update-reminder' , payload).pipe(map(res => res));
  }

  addPreferredZone(employeeId: number, zoneId: number): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}preferred-zone/${employeeId}/${zoneId}`, {}).pipe(map(resp => resp));
  }

  removePreferredZone(employeeId: number, zoneId: number): Observable<any> {
    return this._http.delete<any>(`${this.baseUrlV2}preferred-zone/${employeeId}/${zoneId}`).pipe(map(resp => resp));
  }

  suspendUser(body): Observable<any> {
    // return this._http.post<any>(`http://localhost:8004/api/v2/employee/suspend-user`, body).pipe(map(resp => resp));
    return this._http.post<any>(`${this.baseUrlV2}suspend-user`, body).pipe(map(resp => resp));
  }

  unsuspendUser(body): Observable<any> {
    // return this._http.post<any>(`http://localhost:8004/api/v2/employee/unsuspend-user`, body).pipe(map(resp => resp));
    return this._http.post<any>(`${this.baseUrlV2}unsuspend-user`, body).pipe(map(resp => resp));
  }

  exportComplianceDetailPdf(data?: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}detail-exportPdf`, data).pipe(map(resp => resp));
  }

  updateEmployee(payload) {
    return this._http.put<any>(this.baseUrlV2 + 'update-employee', payload).pipe(map(res => res));
    // return this._http.put<any>('http://localhost:8004/api/v2/employee/update-employee', payload).pipe(map(res => res));
  }

  statistic(payload) {
      return this._http.post<any>(this.baseUrlV2 + 'statistic', payload).pipe(map(res => res));
  }

  statisticCompliance(payload) {
      return this._http.post<any>(this.baseUrlV2 + 'compliance-statistic', payload).pipe(map(res => res));
  }

  resendLoginInfo(data) {
    //return this._http.post<any>('http://localhost:8004/api/v2/employee/' + 'resend-login-info', data).pipe(map(resp => resp));
    return this._http.post<any>(this.baseUrlV2 + 'resend-login-info', data).pipe(map(resp => resp));
  }

  importDataFile(formData: FormData) {
    // return this.http.post<any>(`http://localhost:8004/api/v2/employee/import-file`, formData).pipe(map(res => res));
    return this.http.post<any>(`${this.baseUrlV2}import-file`, formData).pipe(map(res => res));
  }

  updateProfileImage(id, profileImageId) {
    return this._http.get(`${this.baseUrlV2}${id}/profile_image/${profileImageId}`);
  }

  saveImportEmployeeDuplicate(data) {
    // return this.http.post<any>(`http://localhost:8004/api/v2/employee/import-employee-duplicate`, data).pipe(map(res => res));
    return this._http.post(`${this.baseUrlV2}import-employee-duplicate`, data).pipe(map(resp => resp));
  }
}
