import { WeekDay } from './custom-ui.model';
import { v4 as uuidv4 } from 'uuid';
import {FundraisersService} from '../../crm/fundraisers/service/fundraisers.service';

export class Constants {
    public static ROUTES = {
        ADMIN: 'company/api/v2/admin',
        EMPLOYEE: 'employee/api/v2/employee',
        TOPIC: 'employee/api/v2/employee/training/topic',
        OPERATOR: 'operation/api/v2/operation',
        NOTIFICATION: 'notification/api/v2/notification',
        PAYROLL: 'payroll',
        PAYROLL_SETTING: 'payroll/setting',
        CONTACTINFO: 'company/api/v2/contact-info',
        CONTACT_INFO_MAPPING: 'company/api/v2/contact-mapping',
        PAYROLL_HOLIDAY: 'payroll/holiday',
        COMPANY: 'company/api/v2/company',
        OPERATIONAL_POLICY: 'company/api/v2/operational-policy',
        COMPANY_SETTING: 'company/api/v2/company-settings',
        COMPANY_SETTING_HISTORY: 'company/api/v2/company-settings/history',
        COMPANY_OTHER_SETTING_HISTORY: 'company/api/v2/company/other-settings/history',
        GUEST: 'membership/api/v2/guest',
        MY_CONTACT: 'employee/api/v2/employee/my-contact',
        MAIL: 'company/api/v2/mailmanagement',
        EMAIL_TEMPLATE: 'crm-service/api/v2/email-template',
        EMAIL_TEMPLATE_HISTORY: 'crm-service/api/v2/email-template/history',
        DEADLINE_REMINDER: 'company/api/v2/deadline-reminder',
        IP_HOURLY_RESTRICT_HISTORY: 'company/api/v2/company/ip-hourly-restricted-history',
        AUTO_GENERATE_INVOICE_SETTINGS: 'company/api/v2/company/generate-invoice-settings',
        DASHBOARD: {
            GET_DASHBOARD_DATA: 'clocking/api/v2/clocking/getDashboardData',
            GET_DASHBOARD_DATA_NEW: 'clocking/api/v2/clocking/get-dashboard-data'
        },
        CRM: {
            CONTACT: 'crm-service/api/v2/crm/contacts',
            CONTACT_DROPDOWN: 'crm-service/api/v2/crm/contacts/dropdown',
            CLIENT: 'crm-service/api/v2/crm/client',
            CLIENT_TRANSFER: 'crm-service/api/v2/crm/client-transfer',
            CLIENT_RESHUFFLE: 'crm/client-reshuffle',
            EVENT_TYPE: 'crm-service/api/v2/crm/event-types',
            EVENT: 'crm-service/api/v2/crm/events',
            EMPLOYEE_EVENT: 'employee/api/v2/employee/event',
            NOTE_HISTORY: 'crm-service/api/v2/crm/note-history',
            TRANSPORTATION_PROVIDER: 'crm-service/api/v2/crm/transportation/provider',
            EVV_REGISTERED: 'crm-service/api/v2/crm/evv-registered',
            EVV_PHONE_NUMBER_DROP_DOWN: 'drop_down',
            SEARCH: 'search',
            CLIENT_CUSTOMIZE_SEARCH_COLUMN: 'crm-service/api/v2/crm/client-customize-search',
            EXPENSE: 'crm-service/api/v2/crm/expense',
            CLIENT_ACCOUNT:'crm-service/api/v2/crm/client-account',
            CUSTOMIZE_QUESTIONS:'crm-service/api/v2/crm/customize-questions',
            CLIENT_SETTING: 'crm-service/api/v2/crm/client/setting',
            OTHER_EXPENSE: 'crm-service/api/v2/crm/other-expense',
            OPERRTEL_CONTACT: 'crm-service/api/v2/crm/operrtel-contact',
            OPERRTEL_CONTACT_NEW: 'api/v1/api-public/send/account-contact',
            OPERRTEL_CALL_NOTE: 'crm-service/api/v2/crm/operrtel-call-note',
            OPERRTEL_CALL_NOTE_NEW: 'api/v1/api-public/send/call-note',
            OPERRTEL_CALL_LOG: 'crm-service/api/v2/crm/operrtel-call-log',
            OPERRTEL_CONTACT_LIST: 'crm-service/api/v2/crm/operrtel-contact',
            OPERRTEL_CONTACT_LIST_NEW: '/api/v1/api-public/send/account-contact-list',
            TIMER_RECORDS: 'crm-service/api/v2/crm/timer-records',
        },
        CONTRACTOR: 'contractor-service/api/v2',
        FREELANCER:'contractor-service/api/v2/freelancer',
        EXPENSE: 'expense',
        TAX_DETAIL_ROUTE: 'operation/api/v2/operation/tax-detail',
        TAX_DETAIL: 'tax-detail',
        TRANSACTION: 'transaction',
        TRANSACTION_DETAIL: 'transaction/detail',
        FREELANCERTICKET: 'contractor-service/api/v2/freelancerticket',
        FREELANCERTICKET_HISTORY: 'freelancerticket/history',
        SKILL: 'contractor-service/api/v2/skill',
        PHONEPACKAGE: 'phone/api/v2/phone-package',
        PACKAGE: 'phone/api/v2/client-package',
        PACKAGE_INVOICE: 'phone/api/v2/package/invoice',
        PAYMENT_INVOICE: 'phone/api/v2/payment/invoice',
        BILLING_INVOICE: 'operation/api/v2/billing/invoice',
        CONTRACTOR_INVOICE: 'contractor-service/api/v2/contractor/invoice',
        PAYMENT_METHOD: 'contractor-service/api/v2/payment_method',
        CONTRACTOR_PAYROLL: 'contractor-service/api/v2/payroll',
        CONTRACTOR_TEAM: 'contractor-service/api/v2/team',
        USER: 'senior',
        DEPARTMENT: 'operation/api/v2/operation/department',
        SERVICE_RATE: 'operation/api/v2/operation/service-rate',
        SERVICE_PROVIDER: 'operation/api/v2/operation/service-provider',
        QUOTE: 'operation/api/v2/operation/quote',
        LANDER: 'operation/api/v2/operation/lander',
        QUOTE_HISTORY: 'operation/api/v2/operation/quote/history',
        POSITION: 'position',
        PROCESS_MONITOR: 'operation/api/v2/operation/user-process',
        EXPORT_MONITOR: 'operation/api/v2/operation/export-monitor',
        TODO: 'todo',
        TODONOTE: 'operation/api/v2/operation',
        CONTENT: 'content',
        PLAN: 'operation/api/v2/operation/plan',
        MAINTENANCE: 'operation/api/v2/operation/maintenance',
        PWDMNG: 'operation/api/v2/operation/pwd-management',
        VEHICLE: 'inventory/api/v2/inventory/vehicle',
        EMPLOYEE_PAYMENT: 'employee/payment',
        ADJUSTMENT: 'employee/api/v2/operation/employee-payment/adjustment',
        EMPLOYEE_DEPARTMENT: 'employee/api/v2/employee/department',
        EMPLOYEE_CONTACT_INFO: 'employee-contact',
        FILEUPLOAD: 'notification/api/v2/notification/document',
        CONVERT_WEBM_MP4: 'notification/api/v2/notification/document/convert-webm-mp4',
        BROWSER_NOTIFICATION: 'notification/api/v2/notification/browser-notification',
        EMPLOYEECLOCKING: 'clocking/api/v2/clocking',
        EMPLOYMENTAUTHORIZATION: 'employmentAuthorization',
        PERFORMANCE_CRITERIA: 'employee/api/v2/performance-criteria',
        EMPLOYEE_PERFORMANCE: 'employee/api/v2/employee-performance',
        FACERECOGNITION: 'faceRecognition',
        FACEDETECTION: 'face/detect',
        NOTIFCATIONSETTING: 'notification/setting',
        ROLE: 'company/api/v2/role',
        AGENCY: 'company/api/v2/agency',
        AGENCY_SETTING: 'agency-setting',
        AGENCY_HOLIDAY: 'company/api/v2/company',
        AGENCY_IP_ADDRESS: 'ip_address',
        PROTEST_HOUR: 'protest-hour',
        POPUP: 'popup',
        GUESS: 'membership/api/v2/guess',
        MEMBERSHIP: 'membership/api/v2/membership',
        MEMBERSHIP_HISTORY_INFO: 'membership/api/v2/membership/info/history',
        HOLIDAY_HISTORY_INFO: 'company/api/v2/holiday/history',
        ACCOUNT_HISTORY: 'operation/api/v2/operation/billing-account/history',
        EXPENSE_HISTORY: 'expense/history',
        FREELANCER_HISTORY: 'freelancer/history',
        PAYROLL_SETTING_HISTORY: 'payroll/setting',
        AGENCY_HISTORY: 'agency/history',
        PAYMENT: 'payment',
        PAYMENT_TRANSCTION: 'operation/api/v2/operation/payment/transaction',
        PAYMENT_CARD_DEFAULT: 'operation/api/v2/operation/payment/card/default',

        BILLING: 'operation/api/v2/operation/billing',
        INVOICE_TEMPLATE: 'operation/api/v2/operation/invoice-template',
        INVOICE_TEMPLATE_HISTORY: 'operation/api/v2/operation/invoice-template-history',
        PRODUCT: 'crm-service/api/v2/crm-product',
        BILLINGNOTE: 'operation/api/v2/operation/billingnote',
        BILLINGPAYMENT: 'operation/api/v2/operation/billingpayment',
        BILLING_HISTORY: 'operation/api/v2/operation/billing-history',
        PARTICIPANT_HISTORY: 'participant/info/history',
        PAYMENT_HISTORY: 'operation/api/v2/operation/paymenthistory',
        MONITOR_DETAILS: 'operation/api/v2/operation/monitor-details',
        TRANSACTIONTYPE: 'transaction-type',
        LABEL: 'crm-service/api/v2/crm/labels',
        STOCKUSED: 'stock/used',
        CLOCKING : 'clocking/api/v2/clocking',
        CLOCKING_TRACKING : 'clocking/api/v2/clocking/tracking',
        JOB_REPORT:'operation/api/v2/job-report',
        CONCERN_DETAILS:'operation/api/v2/concern-details',
        EXPORT_TODO:'operation/api/v2/operation/todo/export-pdf',
        CONFERENCE_ROOM: 'operation/api/v2/operation/conference_room',
        JOB_FOLLOW_UP: 'crm-service/api/v2/crm/total-job',
        BILLING_TOTAL_JOB: 'crm-service/api/v2/crm/billing-total-job',
        DISPATCH_TOTAL_JOB: 'crm-service/api/v2/crm/dispatch-total-job',
        BILLING_LOG_TOTAL_JOB: 'crm-service/api/v2/crm/billing-log-total-job',
        FIDELIS_BILLING_REPORT: 'crm-service/api/v2/crm/fidelis-billing-report',
        PAYROLL_EMPLPOYEE_REPORT: 'operation/api/v2/operation/payroll-employee-report',
        BILLING_DETAILS_REPORT: 'crm-service/api/v2/crm/billing-details-report',
        TOTAL_JOB_DETAIL: 'crm-service/api/v2/crm/total-job-detail',
        FOLLOW_UP_LABEL: 'crm-service/api/v2/crm/follow-up-label',
        CONFERENCE_ROOM_HISTORY: 'operation/api/v2/operation/conference-room-history',
        ABSENCE_HISTORY: 'employee/api/v2/employee/absence-history',
        TRAINING_SCHEDULE_HISTORY: 'employee/api/v2/employee/in-service/history',
        PRODUCT_PRICE_HISTORY: 'crm-service/api/v2/crm-product/price/history',
        PARKING_BILLING_HISTORY: 'operation/api/v2/operation/parking-billing/history',
        REPORT_TEMPLATE_HISTORY: 'reporting/api/report-template/history',
        CAMPAIGN: 'crm-service/api/v2/crm/campaigns',
        POTENTIAL_CLIENT: 'crm-service/api/v2/crm/potential/client',
        POTENTIAL_CLIENT_HISTORY: 'crm-service/api/v2/crm/potential/client/history',
        CONTRACTOR_FORGOT_PASSWORD:'contractor-service/api/v2/forgot-password',
        CLIENT_FORGOT_PASSWORD:'crm-service/api/v2/forgot-password',
        GUEST_FORGOT_PASSWORD:'membership-service/api/v2/forgot-password',
        GUEST_HISTORY: 'membership/api/v2/guest-history',
        CAMPAIGN_MARKETING: 'crm-service/api/v2/crm/campaign/marketing',
        CAMPAIGN_OPPORTUNITY: 'crm-service/api/v2/crm/campaigns-opportunity',
        TASK_MANAGEMENT:'task-service/api/v2/task/tasks-management',
        TOTAL_JOB_DETAIL_HISTORY: 'crm-service/api/v2/crm/total-job-detail-history',
        SURVEY_SETUP: 'employee/api/v2/survey-setup',
        EQUIPMENT_CHECK: 'inventory/api/v2/inventory/equipment-check-in-out',
        INSURANCE_INFO: 'operation/api/v2/operation/insurance-info',
        INSURANCE_INFO_HISTORY: 'operation/api/v2/operation/insurance-info/history',
        FILE_EXAMINATION: 'operation/api/v2/operation/file-examination',
        CLIENT_BILLING_REPORT: 'crm-service/api/v2/crm/client-billing-report',
        CLIENT_DASHBOAD: 'crm-service/api/v2/crm/client-dashboard',
        BILLING_CORRECTION_REPORT: 'crm-service/api/v2/crm/billing-correction-report',
        BILLING_UPLOAD_HISOTRY: 'crm-service/api/v2/crm/billing-upload-history',
        BILLING_CLIENT_STATUS: 'crm-service/api/v2/crm/billing-client-status',
        VOTER_LIST: 'operation/api/v2/operation/voter-list',
        CANDIDATE: 'operation/api/v2/operation/candidate',
        WALKER: 'operation/api/v2/operation/walker',
        WALKER_FLYER: 'operation/api/v2/operation/walker-flyer',
        ZONE: 'operation/api/v2/operation/zone',
        ZONE_POLYGON: 'operation/api/v2/operation/zone-polygon',
        TURF: 'operation/api/v2/operation/turf',
        SCHEDULE_BATCH: 'operation/api/v2/operation/schedule-batch',
        TRANSACTION_DETAILS_HISTORY: 'operation/api/v2/operation/transaction-detail/history',
        SEND_SMS: 'phone/api/v2/send-sms',
        VOTER_DASHBOAD: 'operation/api/v2/operation/voter-dashboard',
        GENERAL_INVOICE: 'operation/api/v2/operation/general-invoice',
        EMPLOYEE_JOB_REPORT: 'employee/api/v2/employee/employee-job-report',
        EMPLOYEE_JOB_REPORT_NOTE: 'employee/api/v2/employee',
        TODO_HISTORY: 'operation/api/v2/operation/todo-history',
        SHARED_HOLDER_HISTORY: 'operation/api/v2/operation/share-holder-history',
        SHARE_HOLDER_SETUP_HISTORY: 'operation/api/v2/operation/share-holder-setup-history',
        COMPLIANCE_DOCUMENT: 'notification/api/v2/notification/compliance-document',
        TICKET_SUMMARY: 'contractor-service/api/v2/ticket-summary',
        VISIT_HISTORY: 'operation/api/v2/operation/visit-history',
        EMPLOYEE_STATUS_DETAIL: 'employee/api/v2/employee/employee-status',
        DOCUMENT_SETTINGS: 'company/api/v2/company/document-settings',
        SUSPICIOUS_ACTIVITIES: 'company/api/v2/suspicious-activities',
        SMS_MONITORING: 'notification/api/v2/notification/sms-monitoring',
        NOTIFICATION_SEND_SMS: 'notification/api/v2/notification/sms',
        OPERRTEL_REPORT: 'phone/api/v2/operrtel-report',
        GEO: 'operation/api/v2/geo',
        DOCUMENT_FILE: 'notification/api/v2/notification/document-file',
        VRM_GROUP: 'operation/api/v2/operation/vrm-group',
        HISTORICAL_VERSION: 'operation/api/v2/operation/historical-version',
        AUTO_DIAL: 'notification/api/v2/notification/auto-dial-file',
        VOTER_UPLOAD_HISTORY: 'operation/api/v2/operation/voter-upload-history',
        TRANSACTION_FILE_HISTORY: 'operation/api/v2/operation/transaction-file-history',
        INVOICE_TRACKING: 'operation/api/v2/operation/billing/invoice/invoice-tracking',
        INVOICE_TYPE: 'operation/api/v2/operation/invoice-type',
        OPERRTEL_POOL: 'operation/api/v2/operation/operrtel-pool',
        EMPLOYEE_SCHEDULER: 'employee/api/v2/scheduler',
        USER_UNSUBSCRIBE: 'notification/api/v2/notification/user-unsubscribe',
        PACKAGE_SERVICE_PLAN: 'operation/api/v2/operation/package-service-plan',
        DOC_SIGN: 'operation/api/v2/operation/doc-sign',
        DRIP_CAMPAIGN: 'notification/api/v2/notification/drip-campaign',
        PUNCH_CLOCK_BILLING: 'clocking/api/v2/clocking/punch-billing',
        PUNCH_BILLING: 'operation/api/v2/operation/punch-invoice',
        EMPLOYEE_SUSPENSION: 'employee/api/v2/employee/suspension',
        INCIDENT_REPORT: 'operation/api/v2/operation/incident-report',
        INCIDENT_REPORT_HISTORY: 'operation/api/v2/operation/incident-report-history',
        FUNDRAISERS: 'crm-service/api/v2/crm/fundraisers',
        COMPANY_TRACKING: 'company/api/v2/company-tracking',
        VRM_CONTRACT: 'operation/api/v2/operation/vrm-contract',
        USER_LOGIN: 'company/api/v2/user/login',
        USER_GATEWAY: 'company/api/v2/user',
        LOCATION_MANAGEMENT: 'operation/api/v2/operation/location-management',
        RESIDENT: 'resident/api/v2/resident',
        HOURS_OVER: 'resident/api/v2/hours-over',
        USER_CONFIG: 'operation/api/v2/operation/user-config',
        LOCATION_ROOM: 'operation/api/v2/operation/location-room',
        ROOM_INSPECTION: 'operation/api/v2/operation/room-inspection',
        SHORTEN_URL: 'notification/api/v2/notification/shorten',
        RESIDENT_SERVICE: 'resident/api/v2/resident-service',
        INVENTORY_SERVICE: 'inventory/api/v2/inventory',
        RECOLLECTION_DETAILS: 'clocking/api/v2/clocking/recollection-detail',
        TIMESHEET_HISTORY: 'notification/api/v2/timesheet-history',
    };

    public static URI = {
        ADMIN: {
            LOGIN: 'auth/login',
            LOGOUT: 'auth/logout',
            ALLUSERS: 'auth/allUsers',
            USERACTIVITY: '/user-activity',
            GETALLACTIVITIES: '/user-activity',
            GETALLACTIVITIESPAGE: 'user-activity/search',
            LATEST_ACTIVITIES: 'user-activity/get_latest',
            GETLOGINSTODAY: 'auth/loginsToday',
            GETALLPLATFORMADMIN: 'platform_admin',
            DROPDOWNLIST: 'admin_drop_down',
            GETALLCOMPANYADMIN: 'company_admin',
            GETALLAGENCYADMIN: 'agency_admin',
            GET_PUNCH_IN_OUT_ADMIN: 'punchinout_admin',
            GETALLACTIVEAGENCYADMIN: 'active_agency_admin',
            GETAGENCYADMINBYCOMPANYID: 'company/agency_admin',
            GETAGENCYADMINBYAGENCYID: 'company/agency',
            GETALLAGENCYSUBAGENCYADMIN: 'getAllAgencySubAgencyAdmin',
            GROUP: 'company/api/v2/group',
            CHANGE_PASSWORD: '/change-password',
            ACCESS_CONTROL_SETTING: 'access_control_setting',
            REFRESH_TOKEN: 'auth/token',
        },
        DASHBOARD: {
            ROOT: 'dashboard',
            HIGHLIGHT: 'highlight',
        },
        FREELANCER: {
            LOGIN: 'auth/login',
            LOGOUT: 'auth/logout',
        },
        CLIENT: {
            LOGIN: 'auth/login',
            LOGOUT: 'auth/logout',
        },
        CHILD_CLIENT: {
            LOGIN: 'auth/login/child-client',
            LOGOUT: 'auth/logout',
        },
        ABSENCE: {
            ACCRUEDBYEMPLOYEEID: 'accruedByEmployee/',
            APPROVEDLEAVE: 'approved_leave',
            ABSENTIES: 'absenties',
            REPORT: 'report',
            NOTIFICATION: 'employee/notification',
            DELETE_NOTIFICATION: 'employee/notification/delete',

        },
        PAYROLL: {
            PAYROLLSETTING: 'setting',
            PAYROLL_HOLIDAY: 'holiday',
            PAYROLL_TAX_HISTORY: 'payroll-tax-history'
        },
        PURCHASE: {
            PURCHASE: 'purchase'
        },
        DEPARTMENT: {
            LISTALLBYPAGE: 'findAll',
            DROPDOWNLIST: 'dropdown'
        },
        PROCESS_MONITOR: {
            LISTALLBYPAGE: 'search',
        },
        EXPORT_MONITOR: {
            LISTALLBYPAGE: 'search',
        },
        MONITOR_DETAILS : {
            ACTIONS: 'dropdown_actions',
            DROPDOWN_OBJECT_NAME: 'all-object-name',
            USERLIST: 'dropdown_user',
            ACTION_FILTER: 'all-action'
        },
        POSITION: {
            LISTALLBYPAGE: 'findAll',
            LISTALLBYCOMPANYANDAGENCY: 'findAllByCompanyIdAndAgencyId',
        },
        TRANSACTIONTYPE: {
            LISTALLBYPAGE: 'findAll'
        },
        TODO: {
            LISTALLBYPAGE: 'findAll',
            LISTALLBYCOMPANYANDAGENCY: 'findAllByCompanyIdAndAgencyId',
            DROWDOWNLIST: 'drop_down',
            PDFGENEREATE: 'pdfgenerate',
            EXPORTTODOLIST: 'exportToDoList',
            GETTODONOTESLIST : 'getToDoNotesList',
            UPDATESTATUS : 'updateStatus',
            GETCONCERNBYUSERNAME: 'getConcernByUsername'
        },
        EMPLOYEE_DEPARTMENT: {
            LISTALLBYPAGE: 'bydepartment',
        },
        PARTICIPANT: {
            CREATEDBY: '/getCreatedBy',
            ALL: '/search',
            PDFURL: '/pdf'
        },
        TRANSACTION_DETAIL: {
            GETUSER: 'getUser',
            ALL: 'all',
            PDFURL: 'pdf'
        },
        EXPENSE: {
            GETUSER: 'getUser',
            ALL: 'all',
            PDFURL: 'pdf'
        },
        TAX_DETAIL: {
            GETUSER: 'getUser',
            ALL: 'all',
            PDFURL: 'pdf'
        },
        EMPLOYEE: {
            LOGIN: 'auth/login',
            LISTALLBYPAGE: 'findAll',
            BYAGENCYCOMPANY: 'byAgencyCompany',
            BY_AGENCY_AND_COMPANY: 'byAgencyAndCompany',
            DEPARTMENTLIST: 'getDepartment',
            CREATEDBY: 'getCreatedBy',
            GENERATEPIN: 'generatePin',
            GETBYPIN: 'getByPin',
            LISTALL: 'searchList',
            FILTERBYCOMPANY: 'filterByCompany',
            FILTERBYCOMPANYNEW: 'filterByCompanyNew',
            FILTERBYCOMPANYANDAGENCYMANAGER: 'filterByCompanyAndAgencyForManager',
            OVERTIME: 'overtime',
            DROPDOWNLIST: 'dropdown',
            EMPLOYEE_TO_GUEST: 'convert-to-guest',
            DROPDOWNLISTALL: 'drop_down_all',
            LISTBYPOSITION: 'byPosition',
            EVENTSCHEDULE: 'event',
            SEARCH: 'search',
            UPDATEEMPLOYEEANDRELATEDTABLE : 'updateEmployeeAndRelatedTable',
            CREATEPLOYEEANDRELATEDTABLE : 'createEmployeeAndRelatedTable',
            FIND_ALL_PIN_NUMBER:'findAllPinNumberWithoutPageable',
            FIND_ALL_WITHOUT_PAGE:'all',
        },
        EMPLOYEE_PAYMENT: {
            SAVE_ALL: 'saveAll',
            LISTALL: '',
            EXPORT_PAYMENT_RECORD: '/employee-payment/export-payment-record',
            EXPORT_PDF: '/employee-payment/exportPdf',
        },
        COMPANY: {
            entity: 'company',
            LISTALLBYPAGE: 'findAll',
            LISTALL: 'searchList',
            COMPANYSETTING: 'setting',
            COMPANYCONTRACT: 'contract',
            DROPDOWNLIST: 'dropdown',
            ADMINDROPDOWNLIST: 'admin_drop_down',
            COMPANY_OTHER:'other-settings'
        },
        CONTRACT: {
            PDFGENEREATE: 'pdfgenerate',
            COMPANYCONTRACT: 'companycontract'
        },
        GUEST: {
            LISTALLBYPAGE: 'findAll',
            LISTALLBYAGENCY: 'findAllByAgencyId',
            LISTALL: 'searchList',
            ALLTODAY: 'findAllToday',
            COMPANYSETTING: 'setting',
            DROPDOWNLIST: 'drop_down'
        },
        PERFORMANCE_CRITERIA: {
            SEARCH: 'search',
            LISTALL: 'searchList',
            COMPANYSETTING: 'setting',
            DROPDOWNLIST: 'drop_down'
        },
        EMPLOYEE_PERFORMANCE: {
            SAVE_ALL: 'saveAll',
            GET_BY_EMPLOYEE: 'search',
            DELETE_ALL: 'deleteAll',
            GET_PERFORMANCE_LIST: 'getPerformanceList',
            GET_PERFORMANCE_PERIOD : 'periods'
        },
        PAYROLLSETTING: {
            LISTALLBYPAGE: 'findAll',
        },
        PAYROLL_HOLIDAY: {
            BYPAYROLL: 'bypayroll'
        },
        FACERECOGNITION: {
            FACE: 'person/face',
            GETFACES: 'person/faces',
        },
        EMPLOYEECLOCKING: {
            FILTER: 'filter',
            FILTER_ALL: 'filterAll',
            SUMMARY: 'summary',
            CHART_SUMMARY: 'chartSummary',
            SUMMARY_DETAIL: 'summary/detail',
            REPORT: 'report',
            REPORT_DETAILS: 'report/otdetails',
            REPORT_BY_AGENCY: 'report-by-agency',
            EMPLOYEE_PAYMENT_REPORT: 'employee-payment-report',
            ABSENCES_COUNT: 'absencesCount',
            CLOCKED_EXPECTED: 'clocked-expected',
            PUNCH_STATISTIC: 'punch-statistic',
            PUNCH_STATISTIC_V2: 'statistic',
            CHECK_CLOCKING: 'checkClocking',
            IS_EDITED_BEYOND_SETTINGS: 'isPunchInOutEditedBeyondSettings',
            TIME_LEFT_TO_CLOCK_OUT: 'time-left-overtime',
            MISSINGPUNCHCOUNT: 'missingCount',
            MISSING_PUNCH_COUNT_BY_AGENCY: 'missingCountWithFilter',
            MISSING_PUNCH_COUNT_BY_FILTER: 'missingCountFilter',
            PUNCHWITHFILTER: 'punchAndTimeClockWithFilter',
            VIEWSUMMARYWITHFILTER: 'summaryAndTimeClockWithFilter',
            OVERTIMEWITHFILTER: 'overtimeAndTimeClockWithFilter',
            GETFIRSTCLOCKINGOFDATEBYEMPLOYEE: 'first_clocking_of_date_employee',
            GETSECONDCLOCKINGOFDATEBYEMPLOYEE: 'getSecondClockingOfDateByEmployee',
            GETMULTIPLEFIRSTCLOCKINGOFDATEBYEMPLOYEE: 'getMultipleFirstClockingOfDateByEmployee',
            GETMULTIPLESECONDCLOCKINGOFDATEBYEMPLOYEE: 'getMultipleSecondClockingOfDateByEmployee',
            GETLUNCHHOURSFORALLEMPLOYEES: 'getLunchHoursForAllEmployees',
            DO_NEXT_STEP: 'doNextStep',
            COUNT: 'count'
        },
        PACKAGE_INVOICE : {
            FILTER: 'filter',
            CHECKGENERATE: 'checkGenerate',
            GENERATE: 'generate'
        },
        PAYMENT_INVOICE : {
            FILTER: 'filter',
            CHECKGENERATE: 'checkGenerate',
            GENERATE: 'generate'
        },
        BILLING_INVOICE : {
            FILTER: 'search',
            PUBLIC_INVOICE_SEARCH: 'dispatch-invoice-search',
            CHECKGENERATE: 'checkGenerate',
            GENERATE: 'generate',
            INVOICE_SUMMARY_SEARCH: 'invoice-summary/search',
            EXPORT_INVOICES: 'export-invoices',
            GET_INVOICE_DROPDOWN: 'get-all-invoice',
            GET_INACTIVE_REASON_DROPDOWN: 'get-inactive-reason-dropdown'
        },
        CONTRACTOR_INVOICE : {
            FILTER: 'filter',
            CHECKGENERATE: 'checkGenerate',
            GENERATE: 'generate'
        },
        PAYMENT_INFO : {
            PAYMENT_INFO:'payment-info'
        },
        PAYMENT_METHOD : {
            PAYMENT_METHOD:'contractor-service/api/v2/payment_method'
        },
        ROLE: {
            GETPLATFORMADMINROLES: 'platform_admin',
            GETCOMPANYADMINROLES: 'company_admin',
            GETAGENCYADMINROLES: 'agency_admin',
        },
        AGENCY: {
            DROPDOWNLIST: 'drop_down',
            LISTAll: 'byCompId',
            CREATED_USERNAME: 'created-username',
            USERNAME_DROPDOWNLIST: 'admin_drop_down'
        },
        THEME: {
            GETADMINTHEMECONFIG: 'company-service/api/v2/admin/config/get_admin_config',
            PUTADMINTHEMECONFIG: 'company-service/api/v2/admin/config',
        },
        MEMBERSHIP: {
            SAVEMEMBERSHIP: 'membership/details',
            FIND_WITH_FILTERS: '/search',
            DELETEMEMBERSHIP: '',
            UPDATEMEMBERSHIP: '',
            UPDATEBADGEPRINT: 'update_badge_count',
            SAVEMULTIPLEROLWS: 'saveMultipleRows',
            GENERATE_MEMBERSHIP_LIST_PDF: 'membershipListPdf',
            PDF_GENERATE: 'pdfgenerate/generateMemberlistPdf',

        },
        CONTRIBUTIONTYPE: {
            SAVECONTRIBUTIONTYPE: 'membership/api/v2/contribution-type',
            GETALLACONTIBUTIONTYPES: '/search',
            UPDATECONTRIBUTIONTYPE: 'update',
            DELETECONTRIBUTIONTYPE: 'delete',
            HISTORY: '/history',
            //GETFILTEREDDATA: 'get_filtered_data',

        },
        CONTRIBUTION: {
            GETFILTEREDDATA: '/search',
            GETPDF: '/pdf',
            ADDNEWCONTRIBUTION: 'membership/api/v2/contribution',
            REPORT: 'report',
            GETALLCONTRIBUTION: 'getAll',
            GETLOADLCONTRIBUTION:'loadContribution'
        },
        STOCK: {
            STOCK: 'stock',
            GETALLSTOCKTYPES: 'getAll',
            FINDALLSTOCKTYPES: 'findAll',
            PDFURL: 'pdf',
            GETALLADDEDBY: 'getAllAddedBy',
            GETALLSTOCK: 'loadStock'
        },
        STOCKUSED: {
            ADDNEWSTOCK: 'stock',
        },
        STOCKADD: {
            ADDNEWSTOCK: 'stock',
        },
        BILLING: {
            LISTALLBYPAGE: 'findAll',
            BILLERLIST: 'billerList',
            ADDBILLINGNOTE: 'addBillingNotes',
            BILLINGNOTESLIST : 'getNotesList',
            GETPAYMENTSLIST : 'getPaymentsList',
            PDFURL : 'pdf',
            GETFIELDNAMES: 'fieldNames',
            EXPORT_PAYMENT_HISTORY: 'export-pdf'
        },
        NOTES: {
            NOTE: 'note',
            FILES: 'files'
        }
    };

    public static FORM_MODE = {
        CREATE: 'create',
        UPDATE: 'update',
        VIEW: 'view'
    };

    public static NOTE_DATA = {
        SAVE_HISTORY: 'note_history',
        SAVE_NOTE: 'save_note',
        SAVE_EDITED_PUNCH_IN_OUT_NOTE: 'save_edited_punch_in_out_note',
        SAVE_ABSENCE_REQUESTED: 'save_absence_requested',
        UNREAD_TICKET: 'unread_ticket',
    };

    public static CLOCK_OUT_SETTINGS = {
        TIME_TO_SHOW_DIALOG: 1
    };

    public static TIME_SETTINGS = {
        SECONDS_IN_MINUTE: 60
    };

    public static REFRESH_TOKEN_TIMER = 3600000;
    public static APP_KEY = "eaf8ee35d9f9c7335604437104afff4a";
}

export const WEEK_DAYS: WeekDay[] = [
    { label: 'Sun', value: 0 },
    { label: 'Mon', value: 1 },
    { label: 'Tue', value: 2 },
    { label: 'Wed', value: 3 },
    { label: 'Thur', value: 4 },
    { label: 'Fri', value: 5 },
    { label: 'Sat', value: 6 }
];

export const WEEK_DAYS_FULL = {
    '0' : 'Monday',
    '1' : 'Tuesday',
    '2' : 'Wednesday',
    '3' : 'Thursday',
    '4' : 'Friday',
    '5' : 'Saturday',
    '6' : 'Sunday',
};

export const RESPONSE_STATUS = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR'
};

export const ROLE = {
    SUPER_ADMIN: 1,
    SUB_SUPER_ADMIN: 2,
    COMPANY_ADMIN: 3,
    SUB_COMPANY_ADMIN: 4,
    AGENCY_ADMIN: 5,
    SUB_AGENCY_ADMIN: 6
};

export const MONTH_NAMES = {
    '0' : 'January',
    '1' : 'February',
    '2' : 'March',
    '3' : 'April',
    '4' : 'May',
    '5' : 'June',
    '6' : 'July',
    '7' : 'August',
    '8' : 'September',
    '9' : 'October',
    '10' : 'November',
    '11' : 'December',
};
export const LETTERS =[
    'A', 'B', 'C','D','E','F','G','H','I','J','K','L','M','N','O','P',
    'Q','R','S','T','U','V','W','X','Y','Z'
]

export function getDefaultField() {
    return {
        documentFieldType: {
            code: "NOTE",
            name: "Note",
            fieldTitleKey: "NOTE"
        },
        id: uuidv4(),
        priority: 1,
        dataType: "TEXT",
        elementType: "INPUT_TEXT",
        attribute: {
            placeHolder: "Note",
        },
        required: true
    }
}

export const DOCUMENT_DETAILS: any[] = [
    {
        template: {
            name: 'Driver DMV Lic',
            fields: []
        },
        file: null,
        fileName: null,
        fileImageSrc: '',
        fileType: '',
        document: {}
    },
    {
        template: {
            name: 'Termination of Employment Agreement',
            fields: [getDefaultField()]
        },
        file: null,
        fileName: null,
        fileImageSrc: '',
        fileType: '',
        document: {}
    },
    {
        template: {
            name: 'Sick Time and Policies and Procedures',
            fields: [getDefaultField()]
        },
        file: null,
        fileName: null,
        fileImageSrc: '',
        fileType: '',
        document: {}
    },
    {
        template: {
            name: 'Sexual Harassment Policy',
            fields: [getDefaultField()]
        },
        file: null,
        fileName: null,
        fileImageSrc: '',
        fileType: '',
        document: {}
    },
    {
        template: {
            name: 'Mandatory Notice',
            fields: [getDefaultField()]
        },
        file: null,
        fileName: null,
        fileImageSrc: '',
        fileType: '',
        document: {}
    },
    {
        template: {
            name: 'Independent Contractor Information',
            fields: [getDefaultField()]
        },
        file: null,
        fileName: null,
        fileImageSrc: '',
        fileType: '',
        document: {}
    },
    {
        template: {
            name: 'Employment Application',
            fields: [getDefaultField()]
        },
        file: null,
        fileName: null,
        fileImageSrc: '',
        fileType: '',
        document: {}
    },
    {
        template: {
            name: 'Employee Information',
            fields: [getDefaultField()]
        },
        file: null,
        fileName: null,
        fileImageSrc: '',
        fileType: '',
        document: {}
    },
    {
        template: {
            name: 'Emergency Contact',
            fields: [getDefaultField()]
        },
        file: null,
        fileName: null,
        fileImageSrc: '',
        fileType: '',
        document: {}
    },
    {
        template: {
            name: 'Compliance List',
            fields: [getDefaultField()]
        },
        file: null,
        fileName: null,
        fileImageSrc: '',
        fileType: '',
        document: {}
    },
    {
        template: {
            name: 'COMPANY POLICY',
            fields: [getDefaultField()]
        },
        file: null,
        fileName: null,
        fileImageSrc: '',
        fileType: '',
        document: {}
    },
    // {
    //     template: {
    //         name: 'Clean Air Team Member Handbook',
    //         fields: [getDefaultField()]
    //     },
    //     file: null,
    //     fileName: null,
    //     fileImageSrc: '',
    //     fileType: '',
    //     document: {}
    // },
    {
        template: {
            name: 'Anti-Discrimination Policy',
            fields: [getDefaultField()]
        },
        file: null,
        fileName: null,
        fileImageSrc: '',
        fileType: '',
        document: {}
    },
    {
        template: {
            name: 'Employee Handbook',
            fields: [getDefaultField()]
        },
        file: null,
        fileName: null,
        fileImageSrc: '',
        fileType: '',
        document: {}
    },
    {
        template: {
            name: 'Driver Employment Agreement',
            fields: [getDefaultField()]
        },
        file: null,
        fileName: null,
        fileImageSrc: '',
        fileType: '',
        document: {}
    },
    {
        template: {
            name: 'W4 (2023)',
            fields: [getDefaultField()]
        },
        file: null,
        fileName: null,
        fileImageSrc: '',
        fileType: '',
        document: {}
    },
    {
        template: {
            name: 'Pay Rate Notice',
            fields: [getDefaultField()]
        },
        file: null,
        fileName: null,
        fileImageSrc: '',
        fileType: '',
        document: {}
    },
    // {
    //     template: {
    //         name: 'Pay Rate STAFF Chinese Corporation',
    //         fields: [getDefaultField()]
    //     },
    //     file: null,
    //     fileName: null,
    //     fileImageSrc: '',
    //     fileType: '',
    //     document: {}
    // },
    {
        template: {
            name: 'IT 2104 (2023)',
            fields: [getDefaultField()]
        },
        file: null,
        fileName: null,
        fileImageSrc: '',
        fileType: '',
        document: {}
    },
    {
        template: {
            name: 'I9 (2023)',
            fields: [getDefaultField()]
        },
        file: null,
        fileName: null,
        fileImageSrc: '',
        fileType: '',
        document: {}
    },
    {
        template: {
            name: 'Commuter Benifit',
            fields: [getDefaultField()]
        },
        file: null,
        fileName: null,
        fileImageSrc: '',
        fileType: '',
        document: {}
    },
];
